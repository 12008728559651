var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var EL,GL,IL,LL,QL;$CLJS.BL=function(a){return $CLJS.Wh($CLJS.q($CLJS.kC),a,$CLJS.qG)};$CLJS.CL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.DL=new $CLJS.M(null,"object-id","object-id",-754527291);EL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.FL=new $CLJS.M(null,"operators","operators",-2064102509);
GL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.HL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);IL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.JL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.KL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
LL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.ML=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.NL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.OL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.PL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
QL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.RL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.SL=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);$CLJS.X(GL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.BE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.iH],null)],null)],null));$CLJS.X(QL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,GL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.FE],null)],null)],null));$CLJS.X(IL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.mG],null),QL,$CLJS.wE],null));
$CLJS.X(LL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.mG],null),GL,$CLJS.kL],null));
$CLJS.MG.g($CLJS.mG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.mG],null),GL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.kL,$CLJS.wE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.Oi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.iC(a)):null},$CLJS.vt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.nB,LL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,IL],null)],null)],null));$CLJS.uF($CLJS.mG,$CLJS.qG);$CLJS.QE.m(null,$CLJS.mG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Ei,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.cF});$CLJS.BF($CLJS.tB,$CLJS.H([$CLJS.wE]));$CLJS.QE.m(null,$CLJS.tB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Ei,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.cF});
$CLJS.uF($CLJS.tB,$CLJS.qG);$CLJS.X(EL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.BE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.wE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.wE],null)],null)],null));$CLJS.MG.g($CLJS.bG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.bG],null),EL,$CLJS.Zj],null));
$CLJS.QE.m(null,$CLJS.bG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Ei,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.cF});$CLJS.uF($CLJS.bG,$CLJS.qG);$CLJS.BF($CLJS.LF,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VK],null)],null)]));$CLJS.uF($CLJS.LF,$CLJS.qG);
$CLJS.BF($CLJS.hG,$CLJS.H([$CLJS.ot,$CLJS.cF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TK],null)],null)]));$CLJS.uF($CLJS.hG,$CLJS.qG);
$CLJS.X($CLJS.qG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.SE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Gt(", ",$CLJS.YG($CLJS.Jd,$CLJS.kl.g($CLJS.q($CLJS.kC),$CLJS.qG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.BL(a)}],null)],null));