var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.object_details.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var c8,e8,f8,g8,h8,j8,k8,Nra,l8,m8,Ora,n8,Pra,Qra,o8,p8,q8,r8,s8,Rra,t8,Sra,Tra,Ura,Vra,v8,Wra,Xra,Yra,w8,Zra,i8,$ra,asa,bsa,csa,dsa,esa,x8,fsa,gsa,hsa,isa,jsa,ksa,lsa,y8,msa,nsa,osa,psa,qsa,rsa,ssa,tsa,usa,vsa,wsa,xsa,ysa,zsa,Asa,Bsa,z8,A8,Csa,Dsa,Esa,B8,Fsa,Gsa,Hsa,Isa,Jsa,Ksa,Lsa,C8,Msa,Nsa,Osa,Psa;$CLJS.b8=function(a){return $CLJS.gc($CLJS.fb(function(b,c){return $CLJS.hg.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ec($CLJS.N),a))};
c8=function(a){var b=$CLJS.V_(a);return $CLJS.n(b)?$CLJS.lf($CLJS.J_,$CLJS.b0(a,b)):$CLJS.Cf};$CLJS.d8=function(a){return $CLJS.Sk.v(a,$CLJS.tL,$CLJS.be,new $CLJS.h(null,1,[$CLJS.cu,$CLJS.$V],null))};
e8=function(a,b,c){var d=$CLJS.F2(a,$CLJS.JW(a,b)),e=$CLJS.Nk.g($CLJS.UK.h(c),$CLJS.mL)?new $CLJS.h(null,2,[$CLJS.JF,a,$CLJS.HL,b],null):$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.JF,a,$CLJS.HL,d],null):new $CLJS.h(null,2,[$CLJS.JF,$CLJS.d8(a),$CLJS.HL,-1],null),f=$CLJS.C3.g($CLJS.JF.h(e),$CLJS.HL.h(e));a=function(){var k=$CLJS.s2.v($CLJS.JF.h(e),$CLJS.HL.h(e),$CLJS.vW(c),f);if($CLJS.n(k))return k;k=$CLJS.j1.h(c);return $CLJS.n(k)?$CLJS.zH(function(l){return $CLJS.E.g($CLJS.j1.h(l),$CLJS.j1.h(c))},f):k}();
return $CLJS.R.j(e,$CLJS.Ti,a)};f8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=$CLJS.Z5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.K_(c):c:e;return $CLJS.n(d)?(d=$CLJS.D_(c)?null:$CLJS.R.j($CLJS.A($CLJS.R2(c)),$CLJS.cu,$CLJS.XJ),$CLJS.gl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.w6,$CLJS.u6,d],null),e8(a,b,c)]))):null};
g8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=$CLJS.Z5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.Nk.g($CLJS.UK.h(c),$CLJS.mL)&&!$CLJS.J_(c)&&!$CLJS.K_(c)&&!$CLJS.S_(c)&&!$CLJS.H_(c)&&$CLJS.Va($CLJS.y($CLJS.X5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.T6,$CLJS.Ti,c],null):null};
h8=function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.Ti);b=$CLJS.J.g(b,$CLJS.Dj);return $CLJS.n($CLJS.n(a)?null!=b&&$CLJS.Nk.g(b,$CLJS.W2)&&!$CLJS.J_(a)&&$CLJS.I_(a):a)?new $CLJS.h(null,3,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.A6,$CLJS.RF,$CLJS.uW(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$r,$CLJS.N,$CLJS.vW(a),b],null))],null):null};j8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ti),e=$CLJS.J.g(c,$CLJS.Dj);return $CLJS.n($CLJS.n(d)?null!=e:d)?i8(a,b,c,1<$CLJS.D(c8(a))):null};
k8=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.Ti);e=$CLJS.J.g(e,$CLJS.Dj);var f=$CLJS.Z5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.UK.h(c),$CLJS.mL):c:f;return $CLJS.n(c)?$CLJS.lf(d,$CLJS.W5.g(a,b)):null};
Nra=function(a,b){var c=$CLJS.b8($CLJS.hf.g(function(d){d=$CLJS.f0.j(a,b,d);return $CLJS.D_(d)?$CLJS.GP:$CLJS.M_(d)?$CLJS.F7:$CLJS.G_(d)?$CLJS.FG:null},$CLJS.U5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.GP,1],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.FG,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.GP,1,$CLJS.FG,1],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.FG,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.F7,1],null),c)?new $CLJS.ah(null,
new $CLJS.h(null,2,[$CLJS.FG,null,$CLJS.Ky,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.FG,null,$CLJS.Ky,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.FG,1],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.FG,null,$CLJS.Ky,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.FG,2],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.FG,null,$CLJS.Ky,null],null),null):$CLJS.bh};
l8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Ti);c=$CLJS.J.g(d,$CLJS.KL);var f=$CLJS.J.g(d,$CLJS.Dj);if($CLJS.n(function(){var m=$CLJS.Z5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.UK.h(e),$CLJS.mL)&&0<$CLJS.D($CLJS.G4.g(a,b)):e:m}())){var k=Nra(a,b),l=$CLJS.eg.g($CLJS.N,function(){return function u(t){return new $CLJS.ne(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.Ad(v)){var x=$CLJS.lc(v),z=$CLJS.D(x),C=$CLJS.qe(z);a:for(var G=0;;)if(G<z){var K=$CLJS.kd(x,G),S=$CLJS.J.g($CLJS.G7,
K);S=k8(a,b,d,S);$CLJS.n($CLJS.Fe(S))&&C.add(new $CLJS.P(null,2,5,$CLJS.Q,[K,S],null));G+=1}else{x=!0;break a}return x?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}C=$CLJS.A(v);x=$CLJS.J.g($CLJS.G7,C);x=k8(a,b,d,x);if($CLJS.n($CLJS.Fe(x)))return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[C,x],null),u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null},null,null)}(k)}());return $CLJS.td(l)?null:new $CLJS.h(null,4,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.b7,$CLJS.KL,c,$CLJS.m6,l],null)}return null};
m8=function(a,b){return $CLJS.uW($CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
Ora=function(a,b){var c=$CLJS.vW(a);if($CLJS.K_(a))return $CLJS.Cf;if($CLJS.E.g(b,$CLJS.W2))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.T,"\x3d",$CLJS.RF,m8($CLJS.OF,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.T,"≠",$CLJS.RF,m8($CLJS.ZF,$CLJS.H([c]))],null)],null);if($CLJS.E_(a)||$CLJS.D_(a))return a=function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,
u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.RF,m8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ee(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.RF,m8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xr,"\x3c"],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.Tr,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DG,"≠"],null)],null));a=function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.RF,m8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),
f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ee(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.RF,m8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DG,"≠"],null)],null))};
n8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=(e=$CLJS.Z5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.J_(c)&&!$CLJS.I_(c):c:e;return $CLJS.n(e)?(a=e8(a,b,c),$CLJS.gl.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.f6,$CLJS.FL,Ora($CLJS.Ti.h(a),d),$CLJS.Dj,d],null),a]))):null};Pra=function(a,b,c){return $CLJS.s2.v(a,b,$CLJS.vW(c),$CLJS.M7.g(a,b))};
Qra=function(a,b,c){a=$CLJS.O7(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
o8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ti),e=$CLJS.J.g(c,$CLJS.Dj);if($CLJS.n(function(){var k=$CLJS.Z5(a,b);return k?$CLJS.n(d)?null==e&&!$CLJS.K_(d):d:k}())&&$CLJS.n(Pra(a,b,d))){var f=Qra(a,b,d);return new $CLJS.h(null,4,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.G6,$CLJS.Ti,d,$CLJS.J6,function(){switch(f instanceof $CLJS.M?f.T:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cG],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jG],null);default:return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.jG,$CLJS.cG],null)}}()],null)}return null};
p8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=$CLJS.Z5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.K_(c)&&$CLJS.Nk.g($CLJS.UK.h(c),$CLJS.mL)&&$CLJS.Va($CLJS.y($CLJS.X5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.gf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SF],null),$CLJS.F_(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zG,$CLJS.gG],null):null),new $CLJS.h(null,4,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.H6,$CLJS.Ti,c,$CLJS.L6,a],null)):null};
q8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);var e=$CLJS.Z5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.K_(c)&&$CLJS.F_(c)&&$CLJS.Nk.g($CLJS.UK.h(c),$CLJS.mL):c:e;if($CLJS.n(d)&&(d=$CLJS.zH($CLJS.D_,$CLJS.W5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.zH($CLJS.ci,$CLJS.d1.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.F3(d,e);return $CLJS.n($CLJS.OV($CLJS.hf.g($CLJS.vW,$CLJS.ee(f,$CLJS.U5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.Z6,$CLJS.Ti,c,$CLJS.aG,d,$CLJS.ri,$CLJS.a1.h(e)],
null):null}return null};
r8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ti),e=$CLJS.J.g(c,$CLJS.h6),f=$CLJS.J.g(c,$CLJS.KL),k=$CLJS.J.g(c,$CLJS.Dj);return $CLJS.n(function(){var l=$CLJS.Z5(a,b);return l?$CLJS.n(d)?(l=null!=k)?(l=$CLJS.Fe(f),$CLJS.n(l)?!$CLJS.K_(d):l):l:d:l}())?new $CLJS.h(null,6,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.V6,$CLJS.Q6,"number"===typeof k?k:2,$CLJS.TZ,function(){var l=$CLJS.V_(a);l=null==l?null:$CLJS.a0(a,l);return null==l?null:$CLJS.g0.j(a,b,l)}(),$CLJS.KL,f,$CLJS.h6,e],null):null};
s8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ti);d=$CLJS.J.g(d,$CLJS.Dj);if($CLJS.n($CLJS.n(c)?d:c))if(a=$CLJS.A($CLJS.X5.j(a,b,c)),$CLJS.n(a))if(a=$CLJS.l7(a),$CLJS.n(a))if(b=$CLJS.R7(c,d),$CLJS.n(b)){var e=$CLJS.O(b);b=$CLJS.J.g(e,$CLJS.JL);var f=$CLJS.J.g(e,$CLJS.OL);e=$CLJS.J.g(e,$CLJS.GE);var k=$CLJS.HE.h(a);k=k instanceof $CLJS.M?k.T:null;switch(k){case "num-bins":return new $CLJS.h(null,6,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.K6,$CLJS.Ti,c,$CLJS.JL,d,$CLJS.OL,d+e,$CLJS.D6,new $CLJS.h(null,
1,[$CLJS.HE,$CLJS.ci],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.K6,$CLJS.Ti,c,$CLJS.JL,b,$CLJS.OL,f,$CLJS.D6,$CLJS.Sk.j(a,$CLJS.GE,function(l){return l/10})],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
Rra=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.g6);b=$CLJS.R0.j(a,b,$CLJS.KW(a,b));a=$CLJS.zH($CLJS.Q_,b);if($CLJS.n(a)&&(b=$CLJS.zH($CLJS.R_,b),$CLJS.n(b))){var e=function(f){return $CLJS.Re(function(k){var l=$CLJS.Ti.h(k);l=$CLJS.n($CLJS.Zi.h(f))?$CLJS.E.g($CLJS.Zi.h(f),$CLJS.Zi.h(l)):$CLJS.E.g($CLJS.QZ.h(f),$CLJS.QZ.h(l));return $CLJS.n(l)?$CLJS.Dj.h(k):null},d)};return $CLJS.R.l(c,$CLJS.X7,a,$CLJS.H([$CLJS.Y7,b,$CLJS.Z7,e(a),$CLJS.W7,e(b)]))}return null};
t8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.Ti);var d=$CLJS.J.g(c,$CLJS.Dj),e=$CLJS.J.g(c,$CLJS.X7);c=$CLJS.J.g(c,$CLJS.Y7);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.f7,$CLJS.l6,$CLJS.y6,$CLJS.Ti,a,$CLJS.Dj,d,$CLJS.B6,new $CLJS.h(null,2,[$CLJS.Ti,e,$CLJS.GE,b],null),$CLJS.z6,new $CLJS.h(null,2,[$CLJS.Ti,c,$CLJS.GE,b],null)],null):null};Sra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ti);return $CLJS.n(null==b?null:$CLJS.P_(b))?t8(a,10):null};
Tra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ti);return $CLJS.n(null==b?null:$CLJS.O_(b))?t8(a,1):null};Ura=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ti);return $CLJS.n(null==b?null:$CLJS.N_(b))?t8(a,.1):null};
Vra=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.X7);var c=$CLJS.J.g(b,$CLJS.Y7),d=$CLJS.J.g(b,$CLJS.Z7);b=$CLJS.J.g(b,$CLJS.W7);if($CLJS.n($CLJS.n(d)?b:d)){var e=$CLJS.GE.h($CLJS.l7(a));if($CLJS.n(e)){var f=$CLJS.GE.h($CLJS.l7(c));if($CLJS.n(f)){var k=20<=e&&20<=f?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[e/10,f/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.f7,$CLJS.l6,$CLJS.d6,$CLJS.B6,new $CLJS.h(null,
4,[$CLJS.Ti,a,$CLJS.GE,l,$CLJS.Zn,d,$CLJS.jk,d+e],null),$CLJS.z6,new $CLJS.h(null,4,[$CLJS.Ti,c,$CLJS.GE,k,$CLJS.Zn,b,$CLJS.jk,b+f],null)],null)}}}return null};v8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Dj);if($CLJS.n(d)){var e=Rra(a,b,c);return $CLJS.n(e)?$CLJS.Re(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[Sra,Tra,Ura,Vra],null)):null}return null};
Wra=function(a,b,c){return $CLJS.A(function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.A(m);if($CLJS.n(function(){var u=$CLJS.MY(t,$CLJS.mG);return u?$CLJS.N2(t):u}())&&(l=$CLJS.y(function(u,v,x,z){return function K(G){return new $CLJS.ne(null,function(S,V){return function(){for(var Z=G;;)if(Z=$CLJS.y(Z)){if($CLJS.Ad(Z)){var ha=$CLJS.lc(Z),ra=$CLJS.D(ha),Na=$CLJS.qe(ra);return function(){for(var eb=0;;)if(eb<ra){var Sa=$CLJS.kd(ha,
eb),mb=$CLJS.O(Sa);Sa=mb;mb=$CLJS.J.g(mb,$CLJS.Ti);var Ja=$CLJS.s2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[mb],null));mb=$CLJS.n(Ja)?$CLJS.E.g($CLJS.N2(V),$CLJS.N2(mb)):Ja;$CLJS.n(mb)&&(Sa=$CLJS.R.j(Sa,$CLJS.h6,V),Na.add(Sa));eb+=1}else return!0}()?$CLJS.te($CLJS.ve(Na),K($CLJS.mc(Z))):$CLJS.te($CLJS.ve(Na),null)}var zb=$CLJS.A(Z),Pa=zb=$CLJS.O(zb),Za=$CLJS.J.g(zb,$CLJS.Ti);if($CLJS.n(function(){var eb=$CLJS.s2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[Za],null));return $CLJS.n(eb)?$CLJS.E.g($CLJS.N2(V),$CLJS.N2(Za)):
eb}()))return $CLJS.ee($CLJS.R.j(Pa,$CLJS.h6,V),K($CLJS.Lc(Z)));Z=$CLJS.Lc(Z)}else return null}}(u,v,x,z),null,null)}}(k,t,m,l)(c))))return $CLJS.gf.g(l,f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null}},null,null)}($CLJS.U5.g(a,b))}())};Xra=function(a){a=$CLJS.a1.h(a);return $CLJS.n(a)?$CLJS.Hd($CLJS.fh($CLJS.$7),a)?$CLJS.a8.h?$CLJS.a8.h(a):$CLJS.a8.call(null,a):null:null};
Yra=function(a){a=a instanceof $CLJS.M?a.T:null;switch(a){case "quarter":return $CLJS.OE("See this year by quarter");case "month":return $CLJS.OE("See this quarter by month");case "week":return $CLJS.OE("See this month by week");case "day":return $CLJS.OE("See this week by day");case "hour":return $CLJS.OE("See this day by hour");case "minute":return $CLJS.OE("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
w8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ti),e=$CLJS.J.g(c,$CLJS.KL),f=$CLJS.J.g(c,$CLJS.Dj);if($CLJS.n(function(){var l=$CLJS.Z5(a,b);return l?$CLJS.n(d)?(l=$CLJS.Fe(e),$CLJS.n(l)?null!=f:l):d:l}())&&(c=Wra(a,b,e),$CLJS.n(c))){var k=Xra($CLJS.Ti.h(c));return $CLJS.n(k)?new $CLJS.h(null,5,[$CLJS.cu,$CLJS.RL,$CLJS.rE,Yra(k),$CLJS.mj,$CLJS.d7,$CLJS.GF,c,$CLJS.Y6,k],null):null}return null};
Zra=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.KL);return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.gl.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.gl.l($CLJS.H([b,t])),f($CLJS.Lc(k)))}return null}},null,
null)}(c)}())};
i8=function i8(a,b,c,d){c=$CLJS.O(c);var f=$CLJS.J.g(c,$CLJS.Ti),k=$CLJS.J.g(c,$CLJS.g6),l=$CLJS.J.g(c,$CLJS.Dj);l=new $CLJS.h(null,5,[$CLJS.cu,$CLJS.RL,$CLJS.mj,$CLJS.ML,$CLJS.Ti,f,$CLJS.DL,l,$CLJS.CL,d],null);var m=$CLJS.Z5(a,b);if($CLJS.n(function(){var u=$CLJS.J_(f);return u?$CLJS.n(d)?m:d:u}()))return $CLJS.R.j(l,$CLJS.mj,$CLJS.ML);if($CLJS.J_(f))return $CLJS.R.j(l,$CLJS.mj,$CLJS.SL);if($CLJS.I_(f))return $CLJS.R.j(l,$CLJS.mj,$CLJS.NL);if($CLJS.n(function(){var u=$CLJS.Va(d);return u?(u=$CLJS.Fe(k),
$CLJS.n(u)?$CLJS.td($CLJS.G4.g(a,b)):u):u}())){l=c8(a);var t=$CLJS.I(l,0,null);l=$CLJS.Dj.h($CLJS.zH(function(u){return $CLJS.E.g($CLJS.T.h($CLJS.Ti.h(u)),$CLJS.T.h(t))},k));if($CLJS.n($CLJS.n(l)?$CLJS.Nk.g($CLJS.T.h(f),$CLJS.T.h(t)):l))return c=$CLJS.R.l(c,$CLJS.Ti,t,$CLJS.H([$CLJS.Dj,l])),i8.v?i8.v(a,b,c,d):i8.call(null,a,b,c,d)}return null};$ra=new $CLJS.r(null,"_query","_query",1604043437,null);asa=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);
bsa=new $CLJS.r(null,"_stage-number","_stage-number",1726062348,null);csa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);dsa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);esa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);
x8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);fsa=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);gsa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);hsa=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);isa=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);
jsa=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);ksa=new $CLJS.M(null,"drill","drill",-1704624793);lsa=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);y8=new $CLJS.r(null,"_context","_context",-1238791322,null);msa=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);
nsa=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);osa=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);psa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);
qsa=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);rsa=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);ssa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);tsa=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);
usa=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);vsa=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);wsa=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);
xsa=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);ysa=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);zsa=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);Asa=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);
Bsa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);z8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);A8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);Csa=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);
Dsa=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);Esa=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);B8=new $CLJS.r(null,"context","context",810340414,null);Fsa=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);Gsa=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Hsa=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);Isa=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Jsa=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);Ksa=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Lsa=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);C8=new $CLJS.r(null,"query","query",352022017,null);Msa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Nsa=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);
Osa=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);Psa=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var Qsa;$CLJS.N0.m(null,$CLJS.RL,function(a,b,c){return $CLJS.b6.j(a,b,c)});
Qsa=new $CLJS.P(null,13,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return g8},qsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.k6],null)],null),msa,isa,"metabase/lib/drill_thru/distribution.cljc",28,1,16,16,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dA,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(g8)?g8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return f8},Csa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.s6],null)],null),Esa,ysa,"metabase/lib/drill_thru/column_filter.cljc",29,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dA,$CLJS.Vi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(f8)?f8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return h8},Nsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.W6],null)],null),Ksa,rsa,"metabase/lib/drill_thru/fk_filter.cljc",25,1,13,13,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[$ra,bsa,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dA,$CLJS.Vi],null),$CLJS.mt,y8],null)],null)),"Inputs: [_query :- :metabase.lib.schema/query _stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(h8)?h8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return j8},Hsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vs,$CLJS.e6,$CLJS.X6,$CLJS.N6],null)],null)],null),osa,Dsa,"metabase/lib/drill_thru/object_details.cljc",
29,1,47,47,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dA,$CLJS.Vi],null),$CLJS.mt,B8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(j8)?j8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return l8},Isa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.n6],null)],null),asa,tsa,"metabase/lib/drill_thru/pivot.cljc",21,1,76,76,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dA,z8,$CLJS.Vi],null),$CLJS.mt,B8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(l8)?l8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return n8},lsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.O6],null)],null),usa,Psa,"metabase/lib/drill_thru/quick_filter.cljc",28,1,39,39,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dA,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(n8)?n8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return o8},Osa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.g7],null)],null),Asa,jsa,"metabase/lib/drill_thru/sort.cljc",20,1,34,34,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dA,$CLJS.Vi],null),$CLJS.mt,y8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(o8)?o8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return p8},Lsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.v6],null)],null),ssa,xsa,"metabase/lib/drill_thru/summarize_column.cljc",32,1,11,11,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dA,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(p8)?p8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return q8},wsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.t6],null)],null),esa,Gsa,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,15,15,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,
[C8,x8,new $CLJS.h(null,1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dA,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(q8)?
q8.H:null])),A8,!0],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return r8},zsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.e7],null)],null),vsa,fsa,"metabase/lib/drill_thru/underlying_records.cljc",34,1,19,19,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dA,Jsa,z8,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.",
$CLJS.n(r8)?r8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return w8},gsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.$6],null)],null),Msa,hsa,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,
new $CLJS.h(null,1,[$CLJS.op,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dA,z8,$CLJS.Vi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(w8)?w8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return v8},psa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.a7],null)],null),Bsa,Fsa,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,135,135,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,
x8,new $CLJS.h(null,2,[$CLJS.op,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Vi],null),$CLJS.mt,B8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(v8)?v8.H:null])),A8,!1],null),new $CLJS.h(null,2,[$CLJS.gt,new $CLJS.Gc(function(){return s8},dsa,$CLJS.Ig([$CLJS.ql,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.PL,$CLJS.Hs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.c7],null)],null),csa,nsa,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,54,54,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[C8,x8,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dA,$CLJS.Vi],null),$CLJS.mt,y8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(s8)?s8.H:null])),A8,!1],null)],null);
$CLJS.Rsa=function(){function a(d,e,f){try{var k=Zra(f);return $CLJS.eg.g($CLJS.Cf,function(){return function u(t){return new $CLJS.ne(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var z=x,C=$CLJS.A(z),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.gt),S=$CLJS.J.g(G,A8);if(x=$CLJS.y(function(V,Z,ha,ra,Na,zb,Pa,Za){return function mb(Sa){return new $CLJS.ne(null,function(Ja,bc,$d,Id){return function(){for(var qd=Sa;;)if(qd=$CLJS.y(qd)){if($CLJS.Ad(qd)){var ml=$CLJS.lc(qd),u8=$CLJS.D(ml),kp=$CLJS.qe(u8);
a:for(var jQ=0;;)if(jQ<u8){var sB=$CLJS.kd(ml,jQ);sB=Id.j?Id.j(d,e,sB):Id.call(null,d,e,sB);$CLJS.n(sB)&&kp.add(sB);jQ+=1}else{ml=!0;break a}return ml?$CLJS.te($CLJS.ve(kp),mb($CLJS.mc(qd))):$CLJS.te($CLJS.ve(kp),null)}kp=$CLJS.A(qd);kp=Id.j?Id.j(d,e,kp):Id.call(null,d,e,kp);if($CLJS.n(kp))return $CLJS.ee(kp,mb($CLJS.Lc(qd)));qd=$CLJS.Lc(qd)}else return null}}(V,Z,ha,ra,Na,zb,Pa,Za),null,null)}}(v,C,G,K,S,z,x,k)($CLJS.n(function(){var V=S;return $CLJS.n(V)?k:V}())?k:new $CLJS.P(null,1,5,$CLJS.Q,[f],
null))))return $CLJS.gf.g(x,u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null}},null,null)}(Qsa)}())}catch(m){var l=m;throw $CLJS.gi(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.xV(l))].join(""),new $CLJS.h(null,3,[$CLJS.JF,d,$CLJS.HL,e,$CLJS.uN,f],null),l);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.Ssa=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.OA($CLJS.Ly);$CLJS.n($CLJS.NA("metabase.lib.drill-thru",t))&&$CLJS.MA("metabase.lib.drill-thru",t,$CLJS.zW("Applying drill thru: %s",$CLJS.H([$CLJS.AW(new $CLJS.h(null,4,[$CLJS.JF,f,$CLJS.HL,
k,ksa,l,$CLJS.at,m],null))])),null);return $CLJS.Se.N($CLJS.a6,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.A(f);f=$CLJS.B(f);var l=$CLJS.A(f);f=$CLJS.B(f);var m=$CLJS.A(f);f=$CLJS.Lc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();