var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var AZ,Lma,Mma,Nma,Oma,Pma,GZ,Qma,Rma,Sma,JZ,Tma,Uma,Vma,OZ,PZ,Wma,Xma,Yma,YZ,Zma,$ma,ana,a_,bna,cna,dna,ena;AZ=function(){};$CLJS.BZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(AZ,a):$CLJS.$a(AZ,a)};Lma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.CZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Mma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Nma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.DZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.EZ=new $CLJS.M(null,"database-id","database-id",1883826326);Oma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.FZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Pma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);GZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Qma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Rma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Sma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.HZ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.IZ=new $CLJS.M(null,"dataset","dataset",1159262238);JZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Tma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.KZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.LZ=new $CLJS.M(null,"definition","definition",-1198729982);Uma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.MZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Vma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.NZ=new $CLJS.M("source","joins","source/joins",1225821486);OZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
PZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.QZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.RZ=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Wma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.SZ=new $CLJS.M(null,"selected?","selected?",-742502788);
Xma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Yma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.TZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.UZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.VZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.WZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.XZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);YZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Zma=new $CLJS.M(null,"details","details",1956795411);$CLJS.ZZ=new $CLJS.M("source","fields","source/fields",-649667981);$ma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.$Z=new $CLJS.M("source","native","source/native",-1444604147);
ana=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);a_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.b_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);bna=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);cna=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
dna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);ena=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.c_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.d_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(OZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ss,$CLJS.UZ,$CLJS.$Z,$CLJS.DZ,$CLJS.FZ,$CLJS.ZZ,$CLJS.mL,$CLJS.gL,$CLJS.NZ,$CLJS.KZ,$CLJS.MZ],null));$CLJS.X(YZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,ena],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.$K],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aL,$CLJS.kL],null)],null));
$CLJS.X(GZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,bna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.$K],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Ms],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Pma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Ms],null)],null)],null));
$CLJS.X($CLJS.HZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.SK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.FE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.kL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.us,$CLJS.Zj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ei,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.FE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.kL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.kL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.c_,
new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.wE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.wE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UK,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.b_,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.bL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.wE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,new $CLJS.h(null,2,[$CLJS.Zn,1,$CLJS.jk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Wma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[dna,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GZ],null)],null)],null)],null));
$CLJS.X(a_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TZ,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[Sma,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.FE],null)],null)],null)],null)],null)],null));
$CLJS.X(JZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VZ,$CLJS.Ls],null),new $CLJS.P(null,2,5,$CLJS.Q,[Uma,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TZ,$CLJS.wE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RK,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,
$CLJS.wE],null)],null)],null));
$CLJS.X($ma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.lL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.bL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EZ,$CLJS.cL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.ik],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XZ,
new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.ik],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.XK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Nma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null)],null)],null));
$CLJS.X(Xma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.WK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.VK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eL,$CLJS.XK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Pt,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.wE],null)],null)],null));
$CLJS.X(Yma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.YK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.TK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eL,$CLJS.XK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Pt,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.wE],null)],null)],null));
$CLJS.X(ana,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.XK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.wE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.wE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ql,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.wE],null)],null)],null));
$CLJS.X(Mma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.iL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.cL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Zma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.ik],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.d_,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Xi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kA,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.Xi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.ik],null)],null)],null));
$CLJS.X(PZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.BZ},Oma,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[Lma,cna,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Yq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.BZ)?$CLJS.BZ.H:null]))],null));
$CLJS.X(Qma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PZ],null)],null)],null)],null));