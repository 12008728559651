var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var WL,XL,YL,Dha,Eha,bM,Fha,eM,fM,gM,hM,Gha,iM,jM,lM,mM,ZL,nM,Hha,oM,Iha,pM,qM,rM,Jha,sM,tM,Kha,uM,Lha,vM,wM,Mha,yM,Nha,Oha,Pha,AM,BM,CM,DM,EM,FM,GM,HM,Qha,IM,JM,KM,MM,NM,Rha,OM,PM,QM,RM,Sha,SM,TM,UM,VM,Tha,WM,XM,YM,ZM,Uha,$M,Vha,aN,bN,cN,Wha,dN,eN,fN,gN,iN,jN,Xha,kN,lN,Yha,mN,nN,oN,pN,qN,Zha,rN,$ha,sN,tN,vN,aia,wN,xN,yN,bia,BN,CN,cia,dia,DN,FN,GN,HN,eia,KN,LN,fia,gia,hia,MN,NN,ON,PN,QN,RN,iia,SN,jia,TN,UN,WN,YN,ZN,$N,aO,kia,lia,cO,mia,nia,dO,eO,fO,gO,hO,oia,iO,pia,qia,jO,kO,lO,mO,nO,
ria,sia,tia,oO,uia,pO,via,rO,sO,wia,uO,vO,wO,xO,xia,AO,yia,zia,BO,CO,DO,Aia,EO,FO,GO,HO,Bia,JO,KO,LO,MO,NO,Cia,OO,PO,Dia,QO,RO,SO,TO,UO,VO,XO,Eia,YO,Fia,ZO,$O,aP,Gia,Hia,Iia,cP,dP,eP,Jia,fP,gP,hP,iP,Kia,Lia,kP,Mia,Nia,lP,mP,nP,oP,pP,Oia,qP,rP,sP,tP,Pia,uP,vP,wP,xP,zP,Qia,AP,BP,Ria,CP,DP,EP,Sia,FP,Tia,HP,Uia,IP,JP,KP,LP,MP,NP,OP,Via,Wia,Xia,PP,Yia,QP,RP,Zia,$ia,SP,TP,UP,aja,VP,bja,WP,cja,dja,XP,eja,fja,gja,$P,aQ,hja,bQ,cQ,eQ,ija,fQ,jja,gQ,hQ,iQ,kja,lja,kQ,lQ,mQ,nQ,mja,pQ,qQ,nja,rQ,sQ,tQ,uQ,vQ,wQ,xQ,
yQ,oja,zQ,AQ,pja,BQ,qja,CQ,DQ,EQ,rja,FQ,GQ,sja,tja,HQ,IQ,JQ,uja,vja,KQ,wja,xja,yja,MQ,zja,OQ,Aja,Bja,PQ,QQ,Cja,Dja,Eja,RQ,SQ,TQ,UQ,VQ,WQ,XQ,Fja,YQ,ZQ,Gja,$Q,Hja,Ija,Jja,aR,bR,cR,Kja,dR,eR,Lja,fR,Mja,gR,Nja,Oja,iR,jR,kR,lR,mR,Pja,nR,oR,Qja,pR,qR,rR,sR,tR,uR,vR,wR,Rja,xR,yR,AR,BR,CR,DR,ER,FR,GR,HR,IR,JR,Sja,KR,LR,MR,NR,OR,Tja,PR,QR,RR,SR,Uja,UR,Vja,Wja,WR,Xja,YR,Yja,ZR,Zja,$ja,$R,aka,aS,cS,dS,bka,cka,hS,iS,dka,eka,jS,kS,lS,mS,nS,fka,oS,pS,rS,sS,gka;
$CLJS.VL=function(a){var b=$CLJS.yo.g(a,null),c=$CLJS.qF(b,$CLJS.Rs,function(d){return $CLJS.xn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.ql,b,$CLJS.Dj,k,$CLJS.Xs,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
WL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};XL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.An($CLJS.yo.g($CLJS.ql.h(a),null));if($CLJS.n(b)){var e=WL($CLJS.xt.h(b),c);$CLJS.n(e)?(e=$CLJS.Iq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:WL($CLJS.vt.h(b),c)}return null};YL=function(a,b){return $CLJS.vd(a)||$CLJS.Jl(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Dha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.ql),d=$CLJS.J.g(a,$CLJS.mj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Xs,$CLJS.nea),f=$CLJS.J.j(b,$CLJS.iea,!0),k=$CLJS.J.g(b,$CLJS.hC),l=$CLJS.J.j(b,$CLJS.fC,$CLJS.dC),m=XL(a,$CLJS.Uq.h(c),k,b);if($CLJS.n(m))return m;m=XL(a,$CLJS.it.h(c),k,b);if($CLJS.n(m))return m;m=XL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=XL(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=XL(a,$CLJS.Uq.h(c),
l,b);if($CLJS.n(m))return m;m=XL(a,$CLJS.it.h(c),l,b);if($CLJS.n(m))return m;d=XL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=XL(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?XL(a,e.h?e.h($CLJS.bC):e.call(null,$CLJS.bC),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?XL(a,e.h?e.h($CLJS.bC):e.call(null,$CLJS.bC),l,b):f};
Eha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.nl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.ql),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.hC);l=$CLJS.J.j(l,$CLJS.fC,$CLJS.dC);k=$CLJS.Uq.h(k);f=WL($CLJS.eC.h(k),f);f=$CLJS.n(f)?f:WL($CLJS.eC.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Dha(b,c)],null)};
$CLJS.$L=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Dj);a=$CLJS.J.g(b,$CLJS.Xs);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.jea,$CLJS.Qi),f=$CLJS.J.j(d,$CLJS.lea,Eha);return $CLJS.n(a)?$CLJS.fb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Qi,m);l=e.h?e.h(l):e.call(null,l);return ZL(k,c,t,l)},null,a):null};
bM=function(a,b,c){var d=$CLJS.FA($CLJS.q($CLJS.gE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.gE,$CLJS.aM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.cM=function(a){return bM($CLJS.xk,a,function(){return $CLJS.rF.h(a)})};Fha=function(){var a=dM;return bM($CLJS.Rs,a,function(){var b=$CLJS.rF.h(a),c=$CLJS.VL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
eM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};fM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,eM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,eM(c)],null);default:return eM(a)}}else return eM(a)};
gM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
hM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(gM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,fM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,fM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.lt(2,2,b))}())],null)};Gha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
iM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.Oi,Gha,$CLJS.vt,["valid instance of one of these MBQL clauses: ",$CLJS.Gt(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
jM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UL],null)],null)};$CLJS.kM={};lM={};mM={};$CLJS.aM=function aM(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=aM.j?aM.j(k,f,c):aM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
ZL=function ZL(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=YL(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.cC.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=YL(f,c),d=ZL.v?ZL.v(b,l,k,d):ZL.call(null,b,l,k,d),$CLJS.Ol(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Jl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kea],null),ZL.v?ZL.v(f,b,c,d):ZL.call(null,f,b,c,d)):$CLJS.n($CLJS.cC.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.cC,!0],null))};nM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Hha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
oM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Iha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);pM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);qM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);rM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Jha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);sM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);tM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Kha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);uM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Lha=new $CLJS.M(null,"from","from",1815293044);vM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);wM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Mha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.xM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);yM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Nha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Oha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.zM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Pha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);AM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
BM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);CM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);DM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);EM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);FM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
GM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);HM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Qha=new $CLJS.M(null,"lon-max","lon-max",1590224717);IM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);JM=new $CLJS.r(null,"stddev","stddev",775056588,null);KM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.LM=new $CLJS.M(null,"snippet","snippet",953581994);MM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);NM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Rha=new $CLJS.M(null,"lat-field","lat-field",-830652957);OM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);PM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
QM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);RM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Sha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);SM=new $CLJS.M("location","country","location/country",1666636202);TM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
UM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);VM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Tha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);WM=new $CLJS.M(null,"unary","unary",-989314568);XM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
YM=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);ZM=new $CLJS.r(null,"ceil","ceil",-184398425,null);Uha=new $CLJS.M(null,"lon-min","lon-min",-787291357);$M=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Vha=new $CLJS.M(null,"match","match",1220059550);aN=new $CLJS.r(null,"count-where","count-where",2025939247,null);bN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
cN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Wha=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);dN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);eN=new $CLJS.r(null,"sum","sum",1777518341,null);fN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);gN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.hN=new $CLJS.M("date","range","date/range",1647265776);iN=new $CLJS.r(null,"between","between",-1523336493,null);jN=new $CLJS.M(null,"clause-form","clause-form",1820463737);Xha=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);kN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);lN=new $CLJS.r(null,"field","field",338095027,null);Yha=new $CLJS.M(null,"segment-id","segment-id",1810133590);
mN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);nN=new $CLJS.r(null,"not-null","not-null",313812992,null);oN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);pN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);qN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Zha=new $CLJS.M(null,"template-tag","template-tag",310841038);
rN=new $CLJS.M(null,"invalid","invalid",412869516);$ha=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);sN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);tN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.uN=new $CLJS.M(null,"context","context",-830191113);vN=new $CLJS.r(null,"get-year","get-year",704520253,null);
aia=new $CLJS.M(null,"format-rows?","format-rows?",992129486);wN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);xN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);yN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.zN=new $CLJS.M(null,"parameters","parameters",-1229919748);bia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.AN=new $CLJS.M(null,"card","card",-1430355152);BN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);CN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);cia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);dia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);DN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.EN=new $CLJS.M("date","month-year","date/month-year",1948031290);
FN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);GN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);HN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.IN=new $CLJS.M(null,"joins","joins",1033962699);eia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.JN=new $CLJS.M(null,"source-field","source-field",933829534);KN=new $CLJS.r(null,"Field","Field",430385967,null);
LN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);fia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);gia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);hia=new $CLJS.M(null,"items","items",1031954938);MN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);NN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
ON=new $CLJS.M(null,"more","more",-2058821800);PN=new $CLJS.M(null,"first-clause","first-clause",-20953491);QN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);RN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);iia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);SN=new $CLJS.r(null,"contains","contains",-1977535957,null);
jia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);TN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);UN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.VN=new $CLJS.M(null,"widget-type","widget-type",1836256899);WN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.XN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
YN=new $CLJS.r(null,"is-null","is-null",-356519403,null);ZN=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);$N=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);aO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);kia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.bO=new $CLJS.M(null,"required","required",1807647006);lia=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
cO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);mia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);nia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);dO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);eO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);fO=new $CLJS.M("string","contains","string/contains",1602423827);gO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
hO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);oia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);iO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);pia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);qia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
jO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);kO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);lO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);mO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);nO=new $CLJS.r(null,"share","share",1051097594,null);
ria=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);sia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);tia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);oO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);uia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
pO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);via=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.qO=new $CLJS.M(null,"collection","collection",-683361892);rO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);sO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.tO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);wia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);uO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);vO=new $CLJS.r(null,"metric","metric",2049329604,null);wO=new $CLJS.r(null,"concat","concat",-467652465,null);xO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.yO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
xia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.zO=new $CLJS.M("date","relative","date/relative",25987732);AO=new $CLJS.M("location","city","location/city",-1746973325);yia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);zia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);BO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);CO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
DO=new $CLJS.M("number","between","number/between",97700581);Aia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);EO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);FO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);GO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);HO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.IO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Bia=new $CLJS.M(null,"metric-id","metric-id",-686486942);JO=new $CLJS.r(null,"*","*",345799209,null);KO=new $CLJS.r(null,"+","+",-740910886,null);LO=new $CLJS.r(null,"-","-",-471816912,null);MO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);NO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Cia=new $CLJS.M(null,"question","question",-1411720117);OO=new $CLJS.r(null,"asc","asc",1997386096,null);
PO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Dia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);QO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);RO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);SO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);TO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);UO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
VO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.WO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);XO=new $CLJS.M("string","ends-with","string/ends-with",302681156);Eia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);YO=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Fia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
ZO=new $CLJS.r(null,"and","and",668631710,null);$O=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);aP=new $CLJS.r(null,"round","round",-645002441,null);Gia=new $CLJS.M(null,"to","to",192099007);$CLJS.bP=new $CLJS.M("date","single","date/single",1554682003);Hia=new $CLJS.M(null,"action-id","action-id",-1727958578);Iia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);cP=new $CLJS.r(null,"exp","exp",1378825265,null);
dP=new $CLJS.r(null,"Filter","Filter",-424893332,null);eP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Jia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);fP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);gP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);hP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
iP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.jP=new $CLJS.M(null,"source-table","source-table",-225307692);Kia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Lia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);kP=new $CLJS.r(null,"floor","floor",-772394748,null);Mia=new $CLJS.M(null,"middleware","middleware",1462115504);
Nia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);lP=new $CLJS.M(null,"requires-features","requires-features",-101116256);mP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);nP=new $CLJS.M(null,"clause-name","clause-name",-996419059);oP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);pP=new $CLJS.r(null,"now","now",-9994004,null);
Oia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);qP=new $CLJS.r(null,"not","not",1044554643,null);rP=new $CLJS.r(null,"avg","avg",1837937727,null);sP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);tP=new $CLJS.M(null,"max-results","max-results",-32858165);Pia=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);uP=new $CLJS.r(null,"case","case",-1510733573,null);
vP=new $CLJS.r(null,"distinct","distinct",-148347594,null);wP=new $CLJS.r(null,"get-second","get-second",-425414791,null);xP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.yP=new $CLJS.M(null,"join-alias","join-alias",1454206794);zP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Qia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
AP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);BP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Ria=new $CLJS.M(null,"original","original",-445386197);CP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);DP=new $CLJS.r(null,"abs","abs",1394505050,null);EP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Sia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
FP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Tia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.GP=new $CLJS.M(null,"date","date",-1463434462);HP=new $CLJS.M(null,"second-clause","second-clause",-461435645);Uia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);IP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
JP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);KP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);LP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);MP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);NP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);OP=new $CLJS.r(null,"or","or",1876275696,null);
Via=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Wia=new $CLJS.M(null,"constraints","constraints",422775616);Xia=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);PP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Yia=new $CLJS.M(null,"csv-download","csv-download",2141432084);QP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
RP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Zia=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);$ia=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);SP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);TP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
UP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);aja=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);VP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);bja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);WP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);cja=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
dja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);XP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);eja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);fja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);gja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.YP=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.ZP=new $CLJS.M(null,"database","database",1849087575);$P=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);aQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);hja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);bQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
cQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.dQ=new $CLJS.M(null,"expressions","expressions",255689909);eQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);ija=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);fQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
jja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);gQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);hQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);iQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);kja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);lja=new $CLJS.M(null,"action","action",-811238024);
kQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);lQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);mQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);nQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.oQ=new $CLJS.M(null,"native","native",-613060878);mja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);pQ=new $CLJS.M(null,"page","page",849072397);
qQ=new $CLJS.r(null,"length","length",-2065447907,null);nja=new $CLJS.M(null,"dashboard","dashboard",-631747508);rQ=new $CLJS.r(null,"get-week","get-week",752472178,null);sQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);tQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);uQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);vQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
wQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);xQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);yQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);oja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);zQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);AQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
pja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);BQ=new $CLJS.M(null,"internal","internal",-854870097);qja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);CQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);DQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
EQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);rja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);FQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);GQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);sja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
tja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);HQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);IQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);JQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
uja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);vja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);KQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.LQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);wja=new $CLJS.M(null,"public-question","public-question",629369976);xja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
yja=new $CLJS.M(null,"binary","binary",-1802232288);MQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);zja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.NQ=new $CLJS.M(null,"source-query","source-query",198004422);OQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Aja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
Bja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);PQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);QQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Cja=new $CLJS.M(null,"executed-by","executed-by",-739811161);Dja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Eja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);RQ=new $CLJS.M(null,"amount","amount",364489504);SQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);TQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);UQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);VQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
WQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);XQ=new $CLJS.r(null,"trim","trim",-1880116002,null);Fja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);YQ=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);ZQ=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Gja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
$Q=new $CLJS.M("string","\x3d","string/\x3d",983744235);Hja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Ija=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Jja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);aR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);bR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
cR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Kja=new $CLJS.M(null,"lat-min","lat-min",1630784161);dR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);eR=new $CLJS.r(null,"inside","inside",-681932758,null);Lja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);fR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Mja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);gR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Nja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Oja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.hR=new $CLJS.M(null,"card-id","card-id",-1770060179);iR=new $CLJS.M(null,"variadic","variadic",882626057);
jR=new $CLJS.r(null,"upper","upper",1886775433,null);kR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);lR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);mR=new $CLJS.r(null,"optional","optional",-600484260,null);Pja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);nR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
oR=new $CLJS.M(null,"sugar","sugar",-73788488);Qja=new $CLJS.M(null,"lat-max","lat-max",841568226);pR=new $CLJS.r(null,"power","power",702679448,null);qR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);rR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);sR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
tR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);uR=new $CLJS.r(null,"median","median",-2084869638,null);vR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);wR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Rja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);xR=new $CLJS.M(null,"y","y",-1757859776);yR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.zR=new $CLJS.M(null,"binning","binning",1709835866);AR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);BR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);CR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);DR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);ER=new $CLJS.r(null,"Reference","Reference",2024574086,null);FR=new $CLJS.M(null,"b","b",1482224470);
GR=new $CLJS.M(null,"a","a",-2123407586);HR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);IR=new $CLJS.r(null,"replace","replace",853943757,null);JR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Sja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);KR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
LR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);MR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);NR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);OR=new $CLJS.r(null,"segment","segment",675610331,null);Tja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);PR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
QR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);RR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);SR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Uja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.TR=new $CLJS.M(null,"order-by","order-by",1527318070);UR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Vja=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Wja=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.VR=new $CLJS.M(null,"condition","condition",1668437652);WR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Xja=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);YR=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Yja=new $CLJS.M(null,"card-name","card-name",-2035606807);ZR=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Zja=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);$ja=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);$R=new $CLJS.r(null,"log","log",45015523,null);aka=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
aS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.bS=new $CLJS.M(null,"database_type","database_type",-54700895);cS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);dS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.eS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.fS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.gS=new $CLJS.M("date","all-options","date/all-options",549325958);
bka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);cka=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);hS=new $CLJS.M("location","state","location/state",-114378652);iS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);dka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);eka=new $CLJS.M(null,"lon-field","lon-field",517872067);
jS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);kS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);lS=new $CLJS.M(null,"numeric","numeric",-1495594714);mS=new $CLJS.r(null,"variable","variable",1359185035,null);nS=new $CLJS.r(null,"lower","lower",-1534114948,null);fka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
oS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);pS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.qS=new $CLJS.M(null,"limit","limit",-1355822363);rS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);sS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);gka=new $CLJS.M(null,"pulse","pulse",-244494476);var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null),uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FE],null),vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sE],null),wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oE],null),xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AE],null),yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kL],null),hka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bL],null),ika=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null),zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.MI],null),AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QI],null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HI],null),CS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.oi,null,$CLJS.du,null,$CLJS.oj,null,$CLJS.ci,null,$CLJS.Fi,null,$CLJS.xj,null,$CLJS.mk,null,$CLJS.Gj,null,$CLJS.ji,null,$CLJS.Ej,null,$CLJS.pi,null,$CLJS.zi,null],null),null),DS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.qk,null,$CLJS.ZG,null,$CLJS.ci,null,$CLJS.vi,null,$CLJS.bH,null,$CLJS.ij,null,$CLJS.Ui,null],null),null),
ES=$CLJS.eu.g(CS,DS),jka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"date bucketing unit"],null)],null),CS),kka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"time bucketing unit"],null)],null),DS),FS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"datetime bucketing unit"],null)],null),ES),GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null),lka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,
1,[$CLJS.vt,"temporal extract unit"],null),$CLJS.cH,$CLJS.zi,$CLJS.mk,$CLJS.bJ,$CLJS.wJ,$CLJS.xJ,$CLJS.Gj,$CLJS.Fi,$CLJS.vi,$CLJS.ij,$CLJS.$G],null),mka=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"datetime-diff unit"],null),$CLJS.ZG,$CLJS.Ui,$CLJS.qk,$CLJS.du,$CLJS.oj,$CLJS.xj,$CLJS.oi,$CLJS.ji],null),HS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"temporal-extract week extraction mode"],null),$CLJS.WI,$CLJS.eJ,$CLJS.jJ],null),IS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Ui,$CLJS.qk,$CLJS.du,$CLJS.oj,$CLJS.xj,$CLJS.oi,$CLJS.ji],null),PS,oka,XS,YS,ZS,$S,aT,bT,cT,OU,qka,PU,rka,ska,QU,tka,uka,vka;$CLJS.X(YM,hM($CLJS.fJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.Yv],null),$CLJS.Hs],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,IS],null)])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);
$CLJS.X(CP,hM($CLJS.dJ,$CLJS.H(["n",$CLJS.Hs,"unit",IS])));var nka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CP],null);
$CLJS.X(sN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,"valid :absolute-datetime clause",$CLJS.Oi,function(a){if($CLJS.Va(gM($CLJS.$I,a)))a=rN;else{a=$CLJS.hd(a);var b=$CLJS.cM(zS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.GP:$CLJS.gJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[rN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GP,hM($CLJS.$I,
$CLJS.H(["date",zS,"unit",jka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,hM($CLJS.$I,$CLJS.H(["datetime",AS,"unit",FS]))],null)],null));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);$CLJS.X(HN,hM($CLJS.Ky,$CLJS.H(["time",BS,"unit",kka])));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HN],null),MS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.vt,"date or datetime literal"],null),KS,AS,zS],null);
$CLJS.X(sM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.vt,"time literal"],null),LS,BS],null));$CLJS.X(jS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.vt,"temporal literal"],null),MS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null)],null));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jS],null);
$CLJS.X(Gja,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof KS?new $CLJS.Gc(function(){return KS},$CLJS.od(fia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,BQ,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iQ,"metabase/mbql/schema.cljc",69,$CLJS.$I,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:iQ}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof JS?new $CLJS.Gc(function(){return JS},$CLJS.od(bka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,
$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",29,$CLJS.fJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fJ,$CLJS.rk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.Yv],null),$CLJS.Hs],null)),$CLJS.ri,$CLJS.U($CLJS.Qj,$CLJS.U(mR,YO))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:AP}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof LS?new $CLJS.Gc(function(){return LS},$CLJS.od(dja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,BQ,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aS,"metabase/mbql/schema.cljc",27,$CLJS.Ky,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ky,$CLJS.Ky,$CLJS.U($CLJS.Qj,Fja),$CLJS.ri,$CLJS.U($CLJS.Qj,xia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:aS}(),LS],null)])));
$CLJS.X(ZR,hM($CLJS.Dj,$CLJS.H(["value",$CLJS.Ms,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bS,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.us,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,FS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,tS],null)],null)],null)],null)])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZR],null);$CLJS.X(HO,hM($CLJS.tB,$CLJS.H(["expression-name",tS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.ik],null)])));
PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HO],null);
oka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.HE);c=$CLJS.J.g(c,$CLJS.pE);return $CLJS.E.g(b,$CLJS.pE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.HE);c=$CLJS.J.g(c,$CLJS.GE);return $CLJS.E.g(b,$CLJS.GE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"binning strategy"],null),$CLJS.pE,$CLJS.GE,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),wS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.GE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Bl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Wl)],null)],null)],null)],null)));$CLJS.QS=function QS(a){switch(arguments.length){case 1:return QS.h(arguments[0]);case 2:return QS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.QS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.wB);b=$CLJS.J.g(b,$CLJS.sG);return $CLJS.QS.g(a,b)};$CLJS.QS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.wC(a,$CLJS.XC)?CS:$CLJS.wC(a,$CLJS.WD)?DS:$CLJS.wC(a,$CLJS.wD)?ES:null:null;return $CLJS.n(a)?$CLJS.Hd(a,b):!0};$CLJS.QS.A=2;
$CLJS.X(nR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sG,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,FS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zR,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,oka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.QS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.HE)],null)],null));
$CLJS.X(PR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.wB);return"string"===typeof c?b:!0}],null)],null)}(hM($CLJS.mG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,yS,tS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nR],null)],null)]))));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);
$CLJS.X(PP,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},$CLJS.od(TN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DN,"metabase/mbql/schema.cljc",60,$CLJS.tB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.tB,$CLJS.fS,$CLJS.U($CLJS.Qj,$M),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:DN}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},
$CLJS.od(kO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.vr,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],["0.39.0",$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lN,"metabase/mbql/schema.cljc",51,$CLJS.mG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:lN}(),RS],null)])));
$CLJS.SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);$CLJS.X(nM,hM($CLJS.bG,$CLJS.H(["aggregation-clause-index",$CLJS.Hs,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.ik],null)])));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);
$CLJS.X(UM,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof TS?new $CLJS.Gc(function(){return TS},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yR,"metabase/mbql/schema.cljc",23,$CLJS.bG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bG,dka,$CLJS.U($CLJS.Qj,$CLJS.Hs),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:yR}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},
$CLJS.od(TN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DN,"metabase/mbql/schema.cljc",60,$CLJS.tB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.tB,$CLJS.fS,$CLJS.U($CLJS.Qj,$M),
$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:DN}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},$CLJS.od(kO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.vr,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,
$CLJS.ti,$CLJS.kk,$CLJS.ok],["0.39.0",$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lN,"metabase/mbql/schema.cljc",51,$CLJS.mG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:lN}(),RS],null)])));
var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null),VS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.It,null,$CLJS.EG,null,$CLJS.FF,null,$CLJS.KG,null,$CLJS.MF,null,$CLJS.pG,null,$CLJS.WF,null,$CLJS.Ot,null,$CLJS.CG,null,$CLJS.oG,null,$CLJS.LG,null],null),null),WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);
$CLJS.X(uM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return"string"===typeof a?$CLJS.Zj:$CLJS.n(gM(VS,a))?NM:$CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[NM,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.SS],null)],null));XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);
YS=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.IF,null,$CLJS.vJ,null,$CLJS.cs,null,$CLJS.rJ,null,$CLJS.oJ,null,$CLJS.$F,null,$CLJS.ot,null,$CLJS.EF,null,$CLJS.sJ,null,$CLJS.lJ,null,$CLJS.pG,null,$CLJS.HG,null,$CLJS.pJ,null,$CLJS.rG,null,$CLJS.KF,null,$CLJS.YI,null,$CLJS.bs,null,$CLJS.NF,null,$CLJS.WF,null,$CLJS.SI,null,$CLJS.Ux,null,$CLJS.ZI,null,$CLJS.kJ,null,$CLJS.cJ,null,$CLJS.JG,null,$CLJS.kG,null],null),null);
ZS=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.Zr,null,$CLJS.Tr,null,$CLJS.vs,null,$CLJS.xs,null,$CLJS.Vr,null,$CLJS.DG,null,$CLJS.Cs,null,$CLJS.$r,null,$CLJS.Xr,null],null),null);$S=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.Zn,null,$CLJS.BG,null,$CLJS.vG,null,$CLJS.nG,null,$CLJS.GG,null,$CLJS.IG,null,$CLJS.SF,null,$CLJS.YF,null,$CLJS.zM,null,$CLJS.TF,null,$CLJS.AG,null,$CLJS.tG,null,$CLJS.jk,null,$CLJS.Wx,null,$CLJS.gG,null,$CLJS.hG,null,$CLJS.zG,null],null),null);
aT=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.hJ,null,$CLJS.UI,null,$CLJS.tJ,null,$CLJS.bs,null,$CLJS.nJ,null],null),null);bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fR],null);cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FN],null);$CLJS.dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);$CLJS.eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lR],null);
$CLJS.X(sR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,"numeric expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.Xt:$CLJS.n(gM(YS,a))?iP:$CLJS.n(gM($S,a))?$CLJS.bG:$CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.mG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[iP,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bG,$CLJS.eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,
$CLJS.SS],null)],null));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sR],null);
$CLJS.X(CQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,"datetime expression argument",$CLJS.Oi,function(a){return $CLJS.n(gM($S,a))?$CLJS.bG:$CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.n(gM(aT,a))?MN:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bG,$CLJS.eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[MN,$CLJS.dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,MS,$CLJS.SS],null)],null)],null));
var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);
$CLJS.X(eQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,"expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.Xt:$CLJS.Cd(a)?$CLJS.Ls:$CLJS.n(gM(ZS,a))?uQ:$CLJS.n(gM(YS,a))?iP:$CLJS.n(gM(aT,a))?MN:"string"===typeof a?$CLJS.Zj:$CLJS.n(gM(VS,a))?NM:$CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Ls],null),new $CLJS.P(null,2,5,$CLJS.Q,[uQ,cT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[iP,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[MN,$CLJS.dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[NM,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.SS],null)],null));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eQ],null);$CLJS.X(cQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.vt,"numeric expression arg or interval"],null),nka,fT],null));
var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cQ],null);$CLJS.X(FM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,"int greater than zero or numeric expression",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.Xt:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,bT],null)],null));var pka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FM],null);
$CLJS.X(NN,hM($CLJS.pG,$CLJS.H(["a",hT,"b",hT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,hT],null)])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NN],null);$CLJS.X(rM,hM($CLJS.FF,$CLJS.H(["s",XS,"start",pka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,fT],null)])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);$CLJS.X(vQ,hM($CLJS.Ux,$CLJS.H(["s",XS])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null);$CLJS.X(TM,hM($CLJS.CG,$CLJS.H(["s",XS])));
var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);$CLJS.X(XM,hM($CLJS.LG,$CLJS.H(["s",XS])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null);$CLJS.X(pN,hM($CLJS.EG,$CLJS.H(["s",XS])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pN],null);$CLJS.X(aO,hM($CLJS.It,$CLJS.H(["s",XS])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aO],null);$CLJS.X(KM,hM($CLJS.Ot,$CLJS.H(["s",XS])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KM],null);
$CLJS.X(rR,hM($CLJS.KG,$CLJS.H(["s",XS,"match",$CLJS.Zj,"replacement",$CLJS.Zj])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rR],null);$CLJS.X(oS,hM($CLJS.MF,$CLJS.H(["a",XS,"b",XS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,XS],null)])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oS],null);$CLJS.X(QO,hM($CLJS.oG,$CLJS.H(["s",XS,"pattern",$CLJS.Zj])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QO],null);
$CLJS.X(NR,hM($CLJS.bs,$CLJS.H(["x",iT,"y",iT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,iT],null)])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(LR,hM($CLJS.ot,$CLJS.H(["x",fT,"y",iT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,iT],null)])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null);$CLJS.X(KR,hM($CLJS.HG,$CLJS.H(["x",fT,"y",fT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,fT],null)])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KR],null);
$CLJS.X(MR,hM($CLJS.cs,$CLJS.H(["x",fT,"y",fT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,fT],null)])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MR],null);$CLJS.X(bQ,hM($CLJS.kG,$CLJS.H(["x",fT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null);$CLJS.X(VQ,hM($CLJS.IF,$CLJS.H(["x",fT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);$CLJS.X(rS,hM($CLJS.rG,$CLJS.H(["x",fT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rS],null);$CLJS.X(gP,hM($CLJS.NF,$CLJS.H(["x",fT])));
var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gP],null);$CLJS.X(mP,hM($CLJS.KF,$CLJS.H(["x",fT,"y",fT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mP],null);$CLJS.X(JP,hM($CLJS.$F,$CLJS.H(["x",fT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JP],null);$CLJS.X(GM,hM($CLJS.EF,$CLJS.H(["x",fT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GM],null);$CLJS.X(hQ,hM($CLJS.JG,$CLJS.H(["x",fT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);
$CLJS.X(fN,hM($CLJS.cJ,$CLJS.H(["datetime-x",gT,"datetime-y",gT,"unit",mka])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null);$CLJS.X(xN,hM($CLJS.rJ,$CLJS.H(["datetime",gT,"unit",lka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,HS],null)])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xN],null);$CLJS.X(oP,hM($CLJS.ZI,$CLJS.H(["date",gT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oP],null);$CLJS.X(QP,hM($CLJS.vJ,$CLJS.H(["date",gT])));
var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null);$CLJS.X(tM,hM($CLJS.SI,$CLJS.H(["date",gT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);$CLJS.X(pS,hM($CLJS.lJ,$CLJS.H(["date",gT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,HS],null)])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pS],null);$CLJS.X(BO,hM($CLJS.kJ,$CLJS.H(["date",gT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BO],null);$CLJS.X(KP,hM($CLJS.pJ,$CLJS.H(["date",gT])));
var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);$CLJS.X(bN,hM($CLJS.sJ,$CLJS.H(["datetime",gT])));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null);$CLJS.X(GO,hM($CLJS.oJ,$CLJS.H(["datetime",gT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GO],null);$CLJS.X(VM,hM($CLJS.YI,$CLJS.H(["datetime",gT])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null);$CLJS.X(lO,hM($CLJS.UI,$CLJS.H(["datetime",gT,"to",GS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,GS],null)])));
var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null),ST=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"datetime arithmetic unit"],null),$CLJS.bH,$CLJS.ZG,$CLJS.Ui,$CLJS.qk,$CLJS.du,$CLJS.oj,$CLJS.xj,$CLJS.oi,$CLJS.ji],null);$CLJS.X(qM,hM($CLJS.nJ,$CLJS.H(["datetime",gT,"amount",fT,"unit",ST])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null);
$CLJS.X(OM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return hM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.hJ));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null);$CLJS.X(pM,hM($CLJS.tJ,$CLJS.H(["datetime",gT,"amount",fT,"unit",ST])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);
$CLJS.X(HQ,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KO,"metabase/mbql/schema.cljc",51,$CLJS.bs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bs,$CLJS.EE,$CLJS.U($CLJS.Qj,$P),xR,$CLJS.U($CLJS.Qj,$P),ON,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,$P))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:KO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},
$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[eO,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",67,$CLJS.nJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nJ,$CLJS.gJ,$CLJS.U($CLJS.Qj,kS),RQ,$CLJS.U($CLJS.Qj,
WQ),$CLJS.ri,$CLJS.U($CLJS.Qj,dR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:RP}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(Hha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,
$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[eO,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",72,$CLJS.tJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.tJ,$CLJS.gJ,$CLJS.U($CLJS.Qj,kS),RQ,$CLJS.U($CLJS.Qj,WQ),$CLJS.ri,$CLJS.U($CLJS.Qj,dR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(VT)?VT.H:null])):null));
return $CLJS.n(a)?a:iS}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Mja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.UI,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",71,$CLJS.UI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.UI,$CLJS.gJ,$CLJS.U($CLJS.Qj,kS),Gia,$CLJS.U($CLJS.Qj,AR),Lha,$CLJS.U($CLJS.Qj,$CLJS.U(mR,AR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:LN}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(oja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",
45,$CLJS.hJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:pP}(),UT],null)])));$CLJS.WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null);$CLJS.X(vM,hM($CLJS.Cs,$CLJS.H(["first-clause",$CLJS.WT,"second-clause",$CLJS.WT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.WT],null)])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null);
$CLJS.X(hO,hM($CLJS.vs,$CLJS.H(["first-clause",$CLJS.WT,"second-clause",$CLJS.WT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.WT],null)])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hO],null);$CLJS.X(QM,hM($CLJS.xs,$CLJS.H(["clause",$CLJS.WT])));
var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null),$T=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,3,[$CLJS.vt,":field or :expression reference or :relative-datetime",$CLJS.xt,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Oi,function(a){return $CLJS.n(gM($CLJS.fJ,a))?$CLJS.fJ:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fJ,JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.SS],null)],null);
$CLJS.X(EQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.vt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.vs,$CLJS.Ls,$CLJS.Bl,$CLJS.Zj,NS,$T,hT,OS],null)],null));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);
$CLJS.X(VO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,"order comparable",$CLJS.Oi,function(a){return $CLJS.n(gM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.vs,$CLJS.Bl,$CLJS.Zj,NS,hT,$T],null)],null)],null));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VO],null);
$CLJS.X(DR,hM($CLJS.$r,$CLJS.H(["field",aU,"value-or-field",aU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,aU],null)])));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DR],null);$CLJS.X(HR,hM($CLJS.DG,$CLJS.H(["field",aU,"value-or-field",aU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,aU],null)])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);$CLJS.X(CR,hM($CLJS.Xr,$CLJS.H(["field",bU,"value-or-field",bU])));
var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CR],null);$CLJS.X(BR,hM($CLJS.Tr,$CLJS.H(["field",bU,"value-or-field",bU])));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BR],null);$CLJS.X(qN,hM($CLJS.Zr,$CLJS.H(["field",bU,"value-or-field",bU])));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null);$CLJS.X(wN,hM($CLJS.Vr,$CLJS.H(["field",bU,"value-or-field",bU])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wN],null);$CLJS.X(mQ,hM($CLJS.PF,$CLJS.H(["field",bU,"min",bU,"max",bU])));
var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);$CLJS.X(OQ,hM($CLJS.VF,$CLJS.H(["lat-field",bU,"lon-field",bU,"lat-max",bU,"lon-min",bU,"lat-min",bU,"lon-max",bU])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OQ],null);$CLJS.X($N,hM($CLJS.OF,$CLJS.H(["field",$CLJS.SS])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$N],null);$CLJS.X(PO,hM($CLJS.ZF,$CLJS.H(["field",$CLJS.SS])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PO],null);$CLJS.X(rO,hM($CLJS.QF,$CLJS.H(["field",$CLJS.SS])));
var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rO],null);$CLJS.X(yM,hM($CLJS.XF,$CLJS.H(["field",$CLJS.SS])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null),oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null)],null);$CLJS.X(EO,hM($CLJS.dG,$CLJS.H(["field",XS,"string-or-field",XS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,oU],null)])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EO],null);
$CLJS.X(xP,hM($CLJS.xG,$CLJS.H(["field",XS,"string-or-field",XS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,oU],null)])));var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xP],null);$CLJS.X(pO,hM($CLJS.lG,$CLJS.H(["field",XS,"string-or-field",XS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,oU],null)])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null);$CLJS.X(fQ,hM($CLJS.eG,$CLJS.H(["field",XS,"string-or-field",XS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,oU],null)])));
var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fQ],null);$CLJS.X(HM,hM($CLJS.yG,$CLJS.H(["field",$CLJS.SS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.Hs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.Yv,$CLJS.$x,$CLJS.UF],null)],null),"unit",IS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null)],null)],null)])));var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HM],null);
$CLJS.X(xQ,hM($CLJS.LF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VK],null),tS],null)])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null);
$CLJS.X(FN,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(qR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZO,"metabase/mbql/schema.cljc",15,$CLJS.Cs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Cs,PN,$CLJS.U($CLJS.Qj,dP),HP,$CLJS.U($CLJS.Qj,dP),ZN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,dP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:ZO}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},
$CLJS.od(LP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",14,$CLJS.vs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vs,PN,$CLJS.U($CLJS.Qj,dP),HP,$CLJS.U($CLJS.Qj,dP),ZN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,dP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:OP}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(yQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",15,$CLJS.xs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.eS,$CLJS.U($CLJS.Qj,dP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:qP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==
typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",13,$CLJS.Xr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.mG,$CLJS.U($CLJS.Qj,RN),bR,$CLJS.U($CLJS.Qj,RN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:SO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(YR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",14,$CLJS.Zr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.mG,$CLJS.U($CLJS.Qj,RN),bR,$CLJS.U($CLJS.Qj,RN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:RO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&
"undefined"!==typeof mM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(SP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",13,$CLJS.Tr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.mG,$CLJS.U($CLJS.Qj,
RN),bR,$CLJS.U($CLJS.Qj,RN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:TO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(QR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],
[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",14,$CLJS.Vr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.mG,$CLJS.U($CLJS.Qj,RN),bR,$CLJS.U($CLJS.Qj,RN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:$O}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(TP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",13,$CLJS.$r,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.$r,$CLJS.mG,$CLJS.U($CLJS.Qj,cS),bR,$CLJS.U($CLJS.Qj,cS),FQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,cS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:UO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(gN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,
$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CN,"metabase/mbql/schema.cljc",14,$CLJS.DG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DG,$CLJS.mG,$CLJS.U($CLJS.Qj,cS),bR,$CLJS.U($CLJS.Qj,cS),FQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,cS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(dU)?dU.H:null])):null));
return $CLJS.n(a)?a:CN}(),dU],null)])));
$CLJS.X(MP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,"valid filter expression",$CLJS.Oi,function(a){return $CLJS.n(gM(aT,a))?$CLJS.gJ:$CLJS.n(gM(YS,a))?lS:$CLJS.n(gM(VS,a))?$CLJS.Zj:$CLJS.n(gM(ZS,a))?$CLJS.Ls:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,$CLJS.dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[lS,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,iM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(qR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",
15,$CLJS.Cs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Cs,PN,$CLJS.U($CLJS.Qj,dP),HP,$CLJS.U($CLJS.Qj,dP),ZN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,dP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:ZO}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(LP,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",14,$CLJS.vs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vs,PN,$CLJS.U($CLJS.Qj,dP),HP,$CLJS.U($CLJS.Qj,dP),ZN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,dP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(YT)?
YT.H:null])):null));return $CLJS.n(a)?a:OP}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(yQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qP,"metabase/mbql/schema.cljc",15,$CLJS.xs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.eS,$CLJS.U($CLJS.Qj,dP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:qP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(TP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,
$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",13,$CLJS.$r,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.$r,$CLJS.mG,$CLJS.U($CLJS.Qj,cS),bR,$CLJS.U($CLJS.Qj,cS),FQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,cS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(cU)?cU.H:null])):
null));return $CLJS.n(a)?a:UO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(gN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CN,"metabase/mbql/schema.cljc",14,$CLJS.DG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DG,$CLJS.mG,$CLJS.U($CLJS.Qj,cS),bR,$CLJS.U($CLJS.Qj,cS),FQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,cS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:CN}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},
$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",13,$CLJS.Xr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.mG,$CLJS.U($CLJS.Qj,RN),bR,$CLJS.U($CLJS.Qj,RN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:SO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(SP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TO,"metabase/mbql/schema.cljc",13,$CLJS.Tr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.mG,$CLJS.U($CLJS.Qj,RN),bR,$CLJS.U($CLJS.Qj,RN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:TO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(YR,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",14,$CLJS.Zr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.mG,$CLJS.U($CLJS.Qj,RN),bR,$CLJS.U($CLJS.Qj,RN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(gU)?gU.H:null])):
null));return $CLJS.n(a)?a:RO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(QR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",14,$CLJS.Vr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.mG,$CLJS.U($CLJS.Qj,RN),bR,$CLJS.U($CLJS.Qj,RN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:$O}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(fja,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iN,"metabase/mbql/schema.cljc",19,$CLJS.PF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PF,$CLJS.mG,$CLJS.U($CLJS.Qj,RN),$CLJS.Zn,$CLJS.U($CLJS.Qj,RN),$CLJS.jk,$CLJS.U($CLJS.Qj,RN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:iN}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(hja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gR,"metabase/mbql/schema.cljc",23,$CLJS.dG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dG,$CLJS.mG,$CLJS.U($CLJS.Qj,aR),BN,$CLJS.U($CLJS.Qj,aR),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,wQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:gR}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},
$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GN,"metabase/mbql/schema.cljc",21,$CLJS.xG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xG,$CLJS.mG,$CLJS.U($CLJS.Qj,aR),BN,$CLJS.U($CLJS.Qj,aR),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,wQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:GN}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(via,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",20,$CLJS.lG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lG,$CLJS.mG,$CLJS.U($CLJS.Qj,aR),BN,$CLJS.U($CLJS.Qj,aR),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,wQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:SN}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==
typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od($ja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wM,"metabase/mbql/schema.cljc",36,$CLJS.eG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.eG,$CLJS.mG,$CLJS.U($CLJS.Qj,aR),BN,$CLJS.U($CLJS.Qj,aR),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,wQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:wM}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eR,"metabase/mbql/schema.cljc",26,$CLJS.VF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.VF,Rha,$CLJS.U($CLJS.Qj,RN),eka,$CLJS.U($CLJS.Qj,RN),Qja,$CLJS.U($CLJS.Qj,RN),Uha,$CLJS.U($CLJS.Qj,RN),Kja,$CLJS.U($CLJS.Qj,RN),Qha,$CLJS.U($CLJS.Qj,
RN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:eR}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(wia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",28,$CLJS.QF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,$CLJS.mG,$CLJS.U($CLJS.Qj,KN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:zP}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",29,$CLJS.XF,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,$CLJS.mG,$CLJS.U($CLJS.Qj,KN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:nQ}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(Vja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YN,"metabase/mbql/schema.cljc",27,$CLJS.OF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,$CLJS.mG,$CLJS.U($CLJS.Qj,KN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:YN}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(Iia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nN,"metabase/mbql/schema.cljc",28,$CLJS.ZF,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZF,$CLJS.mG,$CLJS.U($CLJS.Qj,KN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:nN}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(Iha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",33,$CLJS.yG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.yG,$CLJS.mG,$CLJS.U($CLJS.Qj,KN),$CLJS.rk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.Hs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.Yv,$CLJS.$x,$CLJS.UF],null)],null)),$CLJS.ri,$CLJS.U($CLJS.Qj,YO),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,kja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:MQ}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.od(Qia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OR,"metabase/mbql/schema.cljc",27,$CLJS.LF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,Yha,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,Aja,$M],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:OR}(),uU],null)]))],null)],null));
$CLJS.X(zQ,hM($CLJS.WF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.vt,":case subclause"],null),$CLJS.WT,hT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),hT],null)],null)],null)])));
var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);
$CLJS.X(fR,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KO,"metabase/mbql/schema.cljc",51,$CLJS.bs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bs,$CLJS.EE,$CLJS.U($CLJS.Qj,$P),xR,$CLJS.U($CLJS.Qj,$P),ON,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,$P))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:KO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},
$CLJS.od(bja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",51,$CLJS.ot,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ot,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ),
xR,$CLJS.U($CLJS.Qj,$P),ON,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,$P))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:LO}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM?new $CLJS.Gc(function(){return wT},$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,nP,$CLJS.Ti,$CLJS.nj,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.iA,"metabase/mbql/schema.cljc",$CLJS.HG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HG,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ),xR,$CLJS.U($CLJS.Qj,WQ),ON,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,WQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.iA}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JO,"metabase/mbql/schema.cljc",51,$CLJS.cs,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cs,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ),xR,$CLJS.U($CLJS.Qj,WQ),ON,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,WQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:JO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof jT?new $CLJS.Gc(function(){return jT},
$CLJS.od(JQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",58,$CLJS.pG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.pG,GR,$CLJS.U($CLJS.Qj,BM),FR,$CLJS.U($CLJS.Qj,
BM),ON,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,BM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:YQ}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(tja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,
$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",56,$CLJS.Ux,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ux,$CLJS.qy,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:qQ}(),lT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(jia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kP,"metabase/mbql/schema.cljc",55,$CLJS.kG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:kP}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(dia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZM,"metabase/mbql/schema.cljc",54,$CLJS.IF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(zT)?
zT.H:null])):null));return $CLJS.n(a)?a:ZM}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(sia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",55,$CLJS.rG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:aP}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(aka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DP,"metabase/mbql/schema.cljc",53,$CLJS.NF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.NF,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:DP}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,
jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[UR,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pR,"metabase/mbql/schema.cljc",69,$CLJS.KF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.KF,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ),xR,$CLJS.U($CLJS.Qj,WQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:pR}(),CT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(Lia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[UR,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FO,"metabase/mbql/schema.cljc",68,$CLJS.$F,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:FO}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[UR,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",67,$CLJS.EF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EF,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(ET)?
ET.H:null])):null));return $CLJS.n(a)?a:cP}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(Xha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[UR,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$R,"metabase/mbql/schema.cljc",67,$CLJS.JG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,$CLJS.EE,$CLJS.U($CLJS.Qj,WQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:$R}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(MM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",61,$CLJS.WF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.WF,$CLJS.gx,$CLJS.U($CLJS.Qj,dO),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,NP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:uP}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od($ha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.cJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",65,$CLJS.cJ,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cJ,mia,$CLJS.U($CLJS.Qj,kS),nia,$CLJS.U($CLJS.Qj,kS),$CLJS.ri,$CLJS.U($CLJS.Qj,Tia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:EP}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(xja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),$CLJS.od(UQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",71,$CLJS.rJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rJ,$CLJS.gJ,$CLJS.U($CLJS.Qj,kS),$CLJS.ri,$CLJS.U($CLJS.Qj,Uja),$CLJS.xi,$CLJS.U($CLJS.Qj,$CLJS.U(mR,sS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:XP}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Eia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vN,"metabase/mbql/schema.cljc",71,$CLJS.ZI,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZI,$CLJS.GP,$CLJS.U($CLJS.Qj,kS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:vN}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(tia,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",74,$CLJS.vJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vJ,$CLJS.GP,$CLJS.U($CLJS.Qj,kS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:VP}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),
$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",72,$CLJS.SI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SI,$CLJS.GP,$CLJS.U($CLJS.Qj,kS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:sQ}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==
typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(Tja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",
71,$CLJS.lJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lJ,$CLJS.GP,$CLJS.U($CLJS.Qj,kS),$CLJS.xi,$CLJS.U($CLJS.Qj,$CLJS.U(mR,sS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:rQ}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",70,$CLJS.kJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kJ,$CLJS.GP,$CLJS.U($CLJS.Qj,kS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:kQ}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),
$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aQ,"metabase/mbql/schema.cljc",78,$CLJS.pJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pJ,$CLJS.GP,$CLJS.U($CLJS.Qj,kS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:aQ}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==
typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(Sia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CM,"metabase/mbql/schema.cljc",
71,$CLJS.sJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sJ,$CLJS.gJ,$CLJS.U($CLJS.Qj,kS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:CM}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dS,"metabase/mbql/schema.cljc",73,$CLJS.oJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oJ,$CLJS.gJ,$CLJS.U($CLJS.Qj,kS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(PT)?PT.H:null])):null));
return $CLJS.n(a)?a:dS}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(Pia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rJ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,
[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",73,$CLJS.YI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YI,$CLJS.gJ,$CLJS.U($CLJS.Qj,kS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:wP}(),QT],null)])));
$CLJS.X(RM,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},$CLJS.od(rja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AQ,"metabase/mbql/schema.cljc",59,$CLJS.FF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.FF,$CLJS.qy,$CLJS.U($CLJS.Qj,aR),$CLJS.Dv,$CLJS.U($CLJS.Qj,pia),$CLJS.Ux,$CLJS.U($CLJS.Qj,$CLJS.U(mR,WQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:AQ}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},
$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",54,$CLJS.CG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,$CLJS.qy,$CLJS.U($CLJS.Qj,aR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:XQ}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(Via,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",55,$CLJS.EG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,$CLJS.qy,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:GQ}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",
55,$CLJS.LG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LG,$CLJS.qy,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:IP}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(Jha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IR,"metabase/mbql/schema.cljc",57,$CLJS.KG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.KG,$CLJS.qy,$CLJS.U($CLJS.Qj,aR),Vha,$CLJS.U($CLJS.Qj,$CLJS.Zj),$CLJS.pA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:IR}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(vja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nS,"metabase/mbql/schema.cljc",55,$CLJS.Ot,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ot,$CLJS.qy,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:nS}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==
typeof mM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",55,$CLJS.It,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.It,$CLJS.qy,$CLJS.U($CLJS.Qj,aR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:jR}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(jja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,
$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",56,$CLJS.MF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.MF,GR,$CLJS.U($CLJS.Qj,aR),FR,$CLJS.U($CLJS.Qj,aR),ON,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,aR))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:wO}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(qja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.dQ,"null",$CLJS.Pda,"null"],
null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tN,"metabase/mbql/schema.cljc",74,$CLJS.oG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.oG,$CLJS.qy,$CLJS.U($CLJS.Qj,aR),$CLJS.oA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:tN}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==
typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof jT?new $CLJS.Gc(function(){return jT},$CLJS.od(JQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dQ,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
YQ,"metabase/mbql/schema.cljc",58,$CLJS.pG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.pG,GR,$CLJS.U($CLJS.Qj,BM),FR,$CLJS.U($CLJS.Qj,BM),ON,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Mi,BM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:YQ}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},
$CLJS.od(MM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",61,$CLJS.WF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WF,$CLJS.gx,$CLJS.U($CLJS.Qj,dO),
$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,NP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:uP}(),vU],null)])));
$CLJS.wU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,":field or :expression reference or expression",$CLJS.Oi,function(a){return $CLJS.n(gM(YS,a))?lS:$CLJS.n(gM(VS,a))?$CLJS.Zj:$CLJS.n(gM(ZS,a))?$CLJS.Ls:$CLJS.n(gM(aT,a))?$CLJS.gJ:$CLJS.n(gM($CLJS.WF,a))?$CLJS.WF:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[lS,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,$CLJS.dT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.SS],null)],null);$CLJS.X(RR,hM($CLJS.Wx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.SS],null)])));var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RR],null);$CLJS.X(FP,hM($CLJS.nG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.SS],null)])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FP],null);$CLJS.X(PM,hM($CLJS.gG,$CLJS.H(["field-or-expression",$CLJS.wU])));
var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);$CLJS.X(JR,hM($CLJS.IG,$CLJS.H(["field-or-expression",$CLJS.wU])));var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JR],null);$CLJS.X(oO,hM($CLJS.SF,$CLJS.H(["field-or-expression",$CLJS.wU])));var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oO],null);$CLJS.X(ZQ,hM($CLJS.zG,$CLJS.H(["field-or-expression",$CLJS.wU])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZQ],null);$CLJS.X(jO,hM($CLJS.Zn,$CLJS.H(["field-or-expression",$CLJS.wU])));
var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null);$CLJS.X(WN,hM($CLJS.jk,$CLJS.H(["field-or-expression",$CLJS.wU])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WN],null);$CLJS.X(QN,hM($CLJS.GG,$CLJS.H(["field-or-expression",$CLJS.wU,"pred",$CLJS.WT])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QN],null);$CLJS.X(gO,hM($CLJS.vG,$CLJS.H(["pred",$CLJS.WT])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gO],null);$CLJS.X(QQ,hM($CLJS.tG,$CLJS.H(["pred",$CLJS.WT])));
var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QQ],null);$CLJS.X(mN,hM($CLJS.BG,$CLJS.H(["field-or-expression",$CLJS.wU])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);$CLJS.X(oM,hM($CLJS.TF,$CLJS.H(["field-or-expression",$CLJS.wU])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null);$CLJS.X(sO,hM($CLJS.AG,$CLJS.H(["field-or-expression",$CLJS.wU])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sO],null);$CLJS.X(fP,hM($CLJS.YF,$CLJS.H(["field-or-expression",$CLJS.wU,"percentile",fT])));
var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fP],null);$CLJS.X(TQ,hM($CLJS.hG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TK],null),tS],null)])));var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TQ],null);
$CLJS.X(PQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,"unnamed aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(gM(YS,a))?iP:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[iP,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(pja,
new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",60,$CLJS.gG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,kR,$CLJS.U($CLJS.Qj,cR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:rP}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(Aia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wR,"metabase/mbql/schema.cljc",64,$CLJS.IG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IG,kR,$CLJS.U($CLJS.Qj,cR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:wR}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==
typeof mM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(ria,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",65,$CLJS.SF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,kR,$CLJS.U($CLJS.Qj,cR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:vP}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(Lja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,
$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.wG,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JM,"metabase/mbql/schema.cljc",76,$CLJS.BG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,kR,$CLJS.U($CLJS.Qj,cR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:JM}(),IU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(eja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eN,"metabase/mbql/schema.cljc",60,$CLJS.zG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,kR,$CLJS.U($CLJS.Qj,cR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:eN}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(Sha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,
$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bt,"metabase/mbql/schema.cljc",60,$CLJS.Zn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zn,kR,$CLJS.U($CLJS.Qj,cR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(DU)?DU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.bt}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ft,"metabase/mbql/schema.cljc",60,$CLJS.jk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,kR,$CLJS.U($CLJS.Qj,cR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:$CLJS.ft}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&
"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.od(oia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vO,"metabase/mbql/schema.cljc",18,$CLJS.hG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,Bia,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.vs,Fia,$M],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:vO}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(Nia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,
$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",62,$CLJS.tG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,$CLJS.ro,$CLJS.U($CLJS.Qj,dP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:nO}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(Tha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aN,"metabase/mbql/schema.cljc",68,$CLJS.vG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,$CLJS.ro,$CLJS.U($CLJS.Qj,dP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:aN}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",66,$CLJS.GG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.GG,kR,$CLJS.U($CLJS.Qj,cR),$CLJS.ro,$CLJS.U($CLJS.Qj,dP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:sP}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(MM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",61,$CLJS.WF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WF,$CLJS.gx,$CLJS.U($CLJS.Qj,dO),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(mR,NP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:uP}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(Eja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.iG,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",
68,$CLJS.AG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,kR,$CLJS.U($CLJS.Qj,cR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:uR}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Zia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,
nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.iG,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",72,$CLJS.YF,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.YF,kR,$CLJS.U($CLJS.Qj,cR),$CLJS.YF,$CLJS.U($CLJS.Qj,WQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(LU)?LU.H:null])):
null));return $CLJS.n(a)?a:SQ}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EM,"metabase/mbql/schema.cljc",77,$CLJS.TF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,kR,$CLJS.U($CLJS.Qj,cR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:EM}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(Pha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,
$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",74,$CLJS.nG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nG,$CLJS.mG,$CLJS.U($CLJS.Qj,$CLJS.U(mR,KN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:eP}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(qia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([lP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,oR,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.HF,"null"],null),null),
$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.wi,"metabase/mbql/schema.cljc",70,$CLJS.Wx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wx,$CLJS.mG,$CLJS.U($CLJS.Qj,$CLJS.U(mR,KN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:$CLJS.wi}(),xU],null)]))],null)],null));
$CLJS.X(gQ,hM($CLJS.zM,$CLJS.H(["aggregation",PQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.vt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),tS],null)],null)])));
$CLJS.X(lR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,2,[$CLJS.vt,"aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(gM($CLJS.zM,a))?$CLJS.zM:cO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[cO,PQ],null)],null));$CLJS.X(IM,hM($CLJS.jG,$CLJS.H(["field",US])));var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IM],null);$CLJS.X(hP,hM($CLJS.cG,$CLJS.H(["field",US])));
OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hP],null);
qka=iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.od(Ija,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
OO,"metabase/mbql/schema.cljc",15,$CLJS.jG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,$CLJS.mG,$CLJS.U($CLJS.Qj,ER)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:OO}(),NU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.od(zia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,
$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",16,$CLJS.cG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.mG,$CLJS.U($CLJS.Qj,ER)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:KQ}(),OU],null)]));
PU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ss,$CLJS.LM,$CLJS.AN,$CLJS.GF,$CLJS.Xt,$CLJS.AB,$CLJS.GP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rE,tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),tS],null)],null);
rka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,PU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.LM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xM,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YP,wS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),wS],null)],null)],null);
ska=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,PU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.AN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hR,wS],null)],null)],null);
QU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,PU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null)],null)],null);tka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vR],null);
uka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,QU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.GF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GF,RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.Xi,$CLJS.Ms],null)],null)],null)],null);
$CLJS.RU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.GP,null,$CLJS.Xt,null,$CLJS.Ls,null,$CLJS.AB,null],null),null);vka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ss],null),$CLJS.RU);
$CLJS.X(DQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.mj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GF,uka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LM,rka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AN,ska],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,QU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,vka],null)],null)],null)],null)],null));
var SU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,tS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,tS],null)],null)],null),wka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,SU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,$CLJS.Ms],null)],null)],null),TU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null),UU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return $CLJS.n($CLJS.kE($CLJS.xd,
$CLJS.oQ)(a))?$CLJS.oQ:$CLJS.qE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,SU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oQ,$CLJS.Ms],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qE,TU],null)],null),VU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[gja,tS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.IO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.ik],null)],null)],null),WU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,ika,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),xka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ss],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.lF,null,$CLJS.VE,null,$CLJS.eF,null,$CLJS.iF,null],null),null)),XU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tR],null);
$CLJS.X(mO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),WU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),UU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VR,$CLJS.WT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),xka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.vt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.By,$CLJS.jA],null),XU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WO,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,VU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.kE($CLJS.EA($CLJS.jP,$CLJS.NQ),$CLJS.Te($CLJS.kE($CLJS.jP,$CLJS.NQ)))],null)],null));var yka=$CLJS.Q,YU;var ZU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null)],null);
if($CLJS.wd(ZU)&&$CLJS.E.g($CLJS.A(ZU),$CLJS.zj)){var $U=$CLJS.y(ZU);$CLJS.A($U);var aV=$CLJS.B($U),zka=$CLJS.xd($CLJS.A(aV))?aV:$CLJS.ee(null,aV),bV=$CLJS.y(zka),Aka=$CLJS.A(bV),Bka=$CLJS.B(bV);YU=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.R.j(Aka,$CLJS.Zn,1)],null),Bka)}else YU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,ZU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(oN,new $CLJS.P(null,3,5,yka,[$CLJS.Cs,YU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"All join aliases must be unique."],null),function(a){return $CLJS.TL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.$E,a)))}],null)],null));var Cka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null);
$CLJS.X(tR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ql,new $CLJS.h(null,1,[$CLJS.vt,"Distinct, non-empty sequence of Field clauses"],null),jM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.SS],null))],null));
$CLJS.X(lQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),UU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),WU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.eT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,new $CLJS.h(null,1,
[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.SS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,tS,$CLJS.wU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),XU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.WT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qS,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TR,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),jM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),qka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[pQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[pQ,wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[hia,wS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),Cka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,VU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.em(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NQ,$CLJS.jP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,
new $CLJS.h(null,1,[$CLJS.vt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.aG);b=$CLJS.J.g(b,$CLJS.WE);return $CLJS.td($CLJS.lu.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.cV=$CLJS.Ig([$CLJS.FG,$CLJS.GP,iO,UN,XO,hS,DO,$CLJS.gS,$CLJS.Xt,CO,SM,$Q,WR,$CLJS.hN,SR,fO,$CLJS.bP,AO,$CLJS.Zi,$CLJS.zO,AM,$CLJS.EN,$CLJS.yO,BP,$CLJS.Ls,$CLJS.AB,uO],[new $CLJS.h(null,1,[NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.FG,null,$CLJS.GP,null,$CLJS.Xt,null,$CLJS.Ls,null,$CLJS.AB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.FG,null,$CLJS.GP,null,$CLJS.gS,null,$CLJS.bP,null,$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.mj,$CLJS.Zj,$CLJS.zE,iR,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[iO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,lS,$CLJS.zE,iR,NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.FG,null,UN,null,$CLJS.Xt,null,$CLJS.Zi,null,AM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.zE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[XO,null],null),null)],null),new $CLJS.h(null,1,[NO,new $CLJS.ah(null,new $CLJS.h(null,1,[hS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,
lS,$CLJS.zE,yja,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[DO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.gS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,lS,NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.FG,null,UN,null,$CLJS.Xt,null,$CLJS.Zi,null,AM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,lS,$CLJS.zE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[CO,null],null),null)],null),new $CLJS.h(null,1,[NO,new $CLJS.ah(null,
new $CLJS.h(null,1,[SM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.zE,iR,NO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.FG,null,hS,null,SM,null,$Q,null,AO,null,$CLJS.Zi,null,AM,null,$CLJS.AB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.zE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[WR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.gS,null,$CLJS.hN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.mj,$CLJS.Zj,$CLJS.zE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[SR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.zE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[fO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.FG,null,$CLJS.GP,null,$CLJS.gS,null,$CLJS.bP,null,$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,1,[NO,new $CLJS.ah(null,new $CLJS.h(null,1,[AO,null],null),null)],null),new $CLJS.h(null,1,[NO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.gS,null,$CLJS.zO,null],null),null)],null),new $CLJS.h(null,1,[NO,new $CLJS.ah(null,new $CLJS.h(null,1,[AM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.gS,null,$CLJS.EN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.GP,NO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.gS,
null,$CLJS.yO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,lS,$CLJS.zE,iR,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[BP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.Ls,NO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.FG,null,$CLJS.Zi,null,$CLJS.Ls,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.Zj,NO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.FG,null,hS,null,SM,null,$Q,null,AO,null,$CLJS.Zi,null,AM,null,$CLJS.AB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,
lS,$CLJS.zE,WM,NO,new $CLJS.ah(null,new $CLJS.h(null,1,[uO,null],null),null)],null)]);$CLJS.X(vR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.cV)));$CLJS.X(dN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.vt,"valid template tag widget type"],null),$CLJS.jA],null),$CLJS.Xg($CLJS.cV)));
$CLJS.X(IQ,hM(Zha,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,tS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,tS],null)],null)],null)])));var dV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);$CLJS.X(DM,hM($CLJS.GF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.SS,dV],null)])));var eV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null);$CLJS.X(yN,hM(xO,$CLJS.H(["target",dV])));
var fV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yN],null),Dka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.SS,iM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof eV?new $CLJS.Gc(function(){return eV},$CLJS.od(Xia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",21,$CLJS.GF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,$CLJS.yJ,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,KN,MO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(eV)?eV.H:null])):null));return $CLJS.n(a)?a:tQ}(),eV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=nP.h($CLJS.pd("undefined"!==typeof $CLJS.kM&&
"undefined"!==typeof lM&&"undefined"!==typeof mM&&"undefined"!==typeof fV?new $CLJS.Gc(function(){return fV},$CLJS.od(mja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,nP,$CLJS.Ti,$CLJS.nj,$CLJS.yk,jN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(UQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mS,"metabase/mbql/schema.cljc",20,xO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[xO,
$CLJS.yJ,$CLJS.U($CLJS.Qj,MO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(fV)?fV.H:null])):null));return $CLJS.n(a)?a:mS}(),fV],null)]))],null),dM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);
$CLJS.X(kN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.kE($CLJS.EA($CLJS.oQ,$CLJS.JF),$CLJS.Te($CLJS.kE($CLJS.oQ,$CLJS.JF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.oQ);var d=$CLJS.J.g(c,$CLJS.JF);c=$CLJS.J.g(c,$CLJS.mj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.vt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.cL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.JF,$CLJS.oQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),wka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),TU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,tka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yJ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),Dka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[eia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),
tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[Xja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),GS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[tP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[cN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,tP);b=$CLJS.J.g(b,cN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[Hja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,[aia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,[Kha,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,[gia,new $CLJS.h(null,1,[$CLJS.Qs,
!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,[zja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.Ls],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mha,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.Ls],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Nja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.Ls],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ly,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.ss,lja,ija,$CLJS.qO,kia,gka,nja,Cia,Yia,uia,bia,yia,wja,Kia,Wha,lia,fka,cka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Cja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,wS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Hia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hR,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,hka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Yja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,wS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[Wja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.Ms,$CLJS.Ms],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Dia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.As,$CLJS.Ms,$CLJS.Ms],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RK,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.Ms],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.WO)],null)],null));var gV=$CLJS.cM(dM);
(function(){var a=Fha();return function(b){if($CLJS.n(gV.h?gV.h(b):gV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.$L(b);throw $CLJS.hi($CLJS.mE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Hy,c,Ria,b],null));}})();