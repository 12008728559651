var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var ffa,gfa,hfa,ifa,jfa,kfa,lfa,SG,mfa,nfa,ofa,pfa,qfa,rfa,sfa,tfa,UG,ufa;$CLJS.NG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);ffa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.OG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);gfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
hfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.PG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.QG=new $CLJS.M(null,"column-name","column-name",551523580);ifa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.RG=new $CLJS.M(null,"display-info","display-info",-816930907);jfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
kfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);lfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);SG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);mfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);nfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ofa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
pfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);qfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);rfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);sfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.TG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);tfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
UG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.VG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);ufa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.DF($CLJS.Wx,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null)],null)]));$CLJS.DF($CLJS.nG,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null)],null)]));
$CLJS.BF($CLJS.gG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));$CLJS.BF($CLJS.SF,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.BF($CLJS.vG,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)]));
$CLJS.BF($CLJS.jk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.uF($CLJS.jk,$CLJS.gF);$CLJS.BF($CLJS.AG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));$CLJS.uF($CLJS.AG,$CLJS.gF);$CLJS.BF($CLJS.Zn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.uF($CLJS.Zn,$CLJS.gF);
$CLJS.X(SG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.vt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.BF($CLJS.YF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SG],null)]));$CLJS.uF($CLJS.YF,$CLJS.gF);
$CLJS.BF($CLJS.tG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)]));$CLJS.BF($CLJS.BG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));$CLJS.BF($CLJS.zG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));
$CLJS.BF($CLJS.IG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));$CLJS.uF($CLJS.zG,$CLJS.gF);$CLJS.uF($CLJS.IG,$CLJS.gF);$CLJS.BF($CLJS.GG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)]));$CLJS.uF($CLJS.GG,$CLJS.gF);
$CLJS.BF($CLJS.TF,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));$CLJS.X(UG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.vs,tfa,rfa,kfa,gfa,ffa,jfa,lfa,mfa,nfa,qfa,ofa,ufa,sfa,hfa,pfa,$CLJS.Dl],null));$CLJS.X($CLJS.OG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UG],null)],null));
$CLJS.WG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.fG,$CLJS.Wx,$CLJS.PG,!1,$CLJS.TG,$CLJS.HF,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Count of rows"),$CLJS.QG,$CLJS.OE("Count"),$CLJS.Pt,$CLJS.OE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.fG,$CLJS.zG,$CLJS.NG,$CLJS.fk,$CLJS.PG,!0,$CLJS.TG,$CLJS.HF,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Sum of ..."),$CLJS.QG,$CLJS.OE("Sum"),$CLJS.Pt,$CLJS.OE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.fG,$CLJS.gG,$CLJS.NG,$CLJS.fk,$CLJS.PG,!0,$CLJS.TG,$CLJS.HF,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Average of ..."),$CLJS.QG,$CLJS.OE("Average"),$CLJS.Pt,$CLJS.OE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.fG,$CLJS.AG,$CLJS.NG,$CLJS.fk,$CLJS.PG,!0,$CLJS.TG,$CLJS.iG,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Median of ..."),$CLJS.QG,$CLJS.OE("Median"),$CLJS.Pt,$CLJS.OE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.fG,$CLJS.SF,$CLJS.NG,$CLJS.Ms,$CLJS.PG,!0,$CLJS.TG,$CLJS.HF,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Number of distinct values of ..."),$CLJS.QG,$CLJS.OE("Distinct values"),$CLJS.Pt,$CLJS.OE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.fG,$CLJS.IG,$CLJS.NG,$CLJS.fk,$CLJS.PG,!0,$CLJS.TG,$CLJS.HF,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Cumulative sum of ..."),
$CLJS.QG,$CLJS.OE("Sum"),$CLJS.Pt,$CLJS.OE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.fG,$CLJS.nG,$CLJS.PG,!1,$CLJS.TG,$CLJS.HF,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Cumulative count of rows"),$CLJS.QG,$CLJS.OE("Count"),$CLJS.Pt,$CLJS.OE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.fG,$CLJS.BG,$CLJS.NG,$CLJS.fk,$CLJS.PG,
!0,$CLJS.TG,$CLJS.wG,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Standard deviation of ..."),$CLJS.QG,$CLJS.OE("SD"),$CLJS.Pt,$CLJS.OE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.fG,$CLJS.Zn,$CLJS.NG,$CLJS.Yi,$CLJS.PG,!0,$CLJS.TG,$CLJS.HF,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Minimum of ..."),$CLJS.QG,$CLJS.OE("Min"),$CLJS.Pt,$CLJS.OE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.fG,$CLJS.jk,$CLJS.NG,$CLJS.Yi,$CLJS.PG,!0,$CLJS.TG,$CLJS.HF,$CLJS.RG,function(){return new $CLJS.h(null,3,[$CLJS.rE,$CLJS.OE("Maximum of ..."),$CLJS.QG,$CLJS.OE("Max"),$CLJS.Pt,$CLJS.OE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ifa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.VG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ss],null),$CLJS.hf.h($CLJS.fG),$CLJS.WG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.Xi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PG,$CLJS.Ls],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.TG,$CLJS.Xi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RG,$CLJS.md],null)],null));