var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var aX,gla,cX,eX,fX,hla,hX,iX,$W,jX,ila,jla,kla,lla,mX,nX,pX,qX,rX,sX,tX,uX,bX,nla,ola,xX,pla,yX,qla,AX,rla,sla,tla,ula,vla,XW;$CLJS.YW=function(a,b){if("string"===typeof b)return XW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.ZW=function(a,b,c){var d=$CLJS.fm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
aX=function(a){var b=$W;return $CLJS.KA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};gla=function(a){var b=bX;return $CLJS.KA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};cX=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Gi,a],null));};
$CLJS.dX=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Rk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};eX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
fX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
hla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Wk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Wk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
hX=function(a,b){for(;;)switch(b=$CLJS.il.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),gX.h?gX.h(a):gX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(fX,a),b))){var c=a;b=hla(a,b);a=c}else{c=$CLJS.hf.g(gX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};iX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.EA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
$W=function(a){return $CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.IA(eX(a).toLowerCase(),/_/,"-")):a};jX=function(a,b){var c=iX(b);return $CLJS.n(c)?(b=$W($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ila=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.mj,$W,$CLJS.VN,$W],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=kX.g?kX.g(b,lX):kX.call(null,b,lX);return d.call(c,b,a)})};
jla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$W(c);var d=ila(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.mj.h(a),$CLJS.GF)&&$CLJS.Va($CLJS.VN.h(a))?$CLJS.R.j(a,$CLJS.VN,$CLJS.FG):a};kla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=eX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(jla(b),$CLJS.T,c)],null)}),a)};
lla=function(a){a=kX.h?kX.h(a):kX.call(null,a);return bX.h?bX.h(a):bX.call(null,a)};mX=function(a){return $CLJS.ZW($CLJS.ZW($CLJS.fb(function(b,c){return $CLJS.ZW(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jD,$CLJS.Fea,$CLJS.IO,mla,$CLJS.eA,$CLJS.ri],null)),$CLJS.jC,lla),$CLJS.XN,$CLJS.AV)};nX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,a,null],null):a};pX=function(a){return oX.h(nX(a))};
qX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return gX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(oX,b)))};rX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,pX(b)],null),$CLJS.hf.g(oX,c))};sX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,pX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
tX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,pX(a)],null)};uX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,oX.h(a)],null)};
bX=function(a){return vX(function(b){if($CLJS.xd(b))return gla(b);if($CLJS.Va(iX(b)))return b;try{return oX.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.OA($CLJS.Hy);if($CLJS.n($CLJS.NA("metabase.mbql.normalize",d))){var e=$CLJS.OE("Invalid clause:");e instanceof Error?$CLJS.MA("metabase.mbql.normalize",d,$CLJS.Fw.l($CLJS.H([b])),e):$CLJS.MA("metabase.mbql.normalize",d,$CLJS.Fw.l($CLJS.H([e,b])),null)}throw $CLJS.gi($CLJS.mE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.xV(c)])),new $CLJS.h(null,
1,[$CLJS.eS,b],null),c);}throw f;}},a)};nla=function(a){return $CLJS.Df($CLJS.cm(bX,wX(a)))};ola=function(a){for(;;)if($CLJS.n(iX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Rk.g(nX,a))};xX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.GF);return $CLJS.n(b)?$CLJS.Sk.j(a,$CLJS.GF,oX):a};
pla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,xX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,xX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};yX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.LQ);return $CLJS.n(b)?$CLJS.Sk.j(a,$CLJS.LQ,pla):a};qla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.oQ);b=$CLJS.Va(a)?zX.h?zX.h(b):zX.call(null,b):b;return $CLJS.n(a)?yX(b):b};AX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
rla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.JF);var c=$CLJS.J.g(b,$CLJS.zN),d=$CLJS.J.g(b,$CLJS.WO),e=$CLJS.J.g(b,$CLJS.oQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.WO);var l=$CLJS.aM($CLJS.Hk.g(f,$CLJS.WO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,$CLJS.WO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Sk.j(l,$CLJS.JF,zX):l,t=$CLJS.n(c)?$CLJS.Sk.j(m,$CLJS.zN,$CLJS.Ve($CLJS.Rk,bX)):m,u=$CLJS.n(e)?$CLJS.Sk.j(t,$CLJS.oQ,yX):t;return bX(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.gi($CLJS.mE("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.xV(m)])),new $CLJS.h(null,1,[$CLJS.JF,a],null),m);throw v;}};
sla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.JF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.aG);c=$CLJS.J.g(c,$CLJS.WE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.vC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.mG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,m,$CLJS.Hk.g(t,$CLJS.sG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.FV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.BX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,$CLJS.WE],null),$CLJS.Pk.g($CLJS.Df,$CLJS.Ve($CLJS.Qk,b)))):a};
tla=function(a){try{return sla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.OE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.JF,a],null),b);}throw c;}};
ula=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=CX.g?CX.g(C,K):CX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return CX.g?CX.g(z,C):CX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};vla=function(a,b){a=$CLJS.Rk.g(function(c){var d=$CLJS.be.g(b,DX);return CX.g?CX.g(c,d):CX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
XW=function XW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),XW.g?XW.g(a,k):XW.call(null,a,k)):null},null,null))};
$CLJS.BX=function BX(a){switch(arguments.length){case 3:return BX.j(arguments[0],arguments[1],arguments[2]);case 4:return BX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return BX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return BX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return BX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.BX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.BX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.BX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.BX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.BX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.BX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.BX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.BX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.BX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.BX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.BX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.BX.A=6;
var vX=function vX(a,b){return $CLJS.yV($CLJS.Ve(vX,a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},gX=function gX(a){for(;;){if($CLJS.xd(a))return $CLJS.Ft(a,gX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ml(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return hX($CLJS.vs,
$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return hX($CLJS.Cs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return hX($CLJS.Cs,t);case "or":return hX($CLJS.vs,t);default:return $CLJS.Rk.g(gX,a)}}else return a}},wla=new $CLJS.M(null,"value_field","value_field",
-980977878),EX=new $CLJS.M(null,"ascending","ascending",-988350486),FX=new $CLJS.M(null,"named","named",-422393479),GX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),HX=new $CLJS.M(null,"descending","descending",-24766135),IX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),xla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),JX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),KX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),yla=new $CLJS.M(null,"rows","rows",850049680),zla=new $CLJS.M(null,"special-fn","special-fn",1290649344),DX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),lX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Ala=new $CLJS.M(null,"label_field","label_field",-1573182765),Bla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Cla=new $CLJS.M(null,"joined-field","joined-field",-2048778268),mla=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var LX,Dla=$CLJS.Xe($CLJS.N),Ela=$CLJS.Xe($CLJS.N),Fla=$CLJS.Xe($CLJS.N),Gla=$CLJS.Xe($CLJS.N),Hla=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));LX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Pk.g($W,$CLJS.A),Hla,Dla,Ela,Fla,Gla);LX.m(null,$CLJS.bG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bG,b],null);return null!=a?$CLJS.be.g(b,a):b});
LX.m(null,$CLJS.tB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,a instanceof $CLJS.M?eX(a):a],null)});LX.m(null,$CLJS.HV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(LX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HV,kX.g?kX.g(b,lX):kX.call(null,b,lX),$W(c)],null)});
LX.m(null,$CLJS.mG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=kX.g?kX.g(a,lX):kX.call(null,a,lX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,b,function(){var d=$CLJS.n($CLJS.wB.h(c))?$CLJS.Sk.j(c,$CLJS.wB,$CLJS.zh):c;d=$CLJS.n($CLJS.sG.h(c))?$CLJS.Sk.j(d,$CLJS.sG,$CLJS.zh):d;return $CLJS.n($CLJS.zR.h(c))?$CLJS.Sk.j(d,$CLJS.zR,function(e){return $CLJS.n($CLJS.HE.h(e))?$CLJS.Sk.j(e,$CLJS.HE,$CLJS.zh):e}):d}()],null)});
LX.m(null,JX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[JX,b instanceof $CLJS.M?eX(b):b,$CLJS.zh.h(a)],null)});LX.m(null,IX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[IX,kX.g?kX.g(b,lX):kX.call(null,b,lX),$CLJS.mt,$W(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[IX,kX.g?kX.g(b,lX):kX.call(null,b,lX),$W(c)],null)});
LX.m(null,$CLJS.yG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(LX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.yG,b,c,d],null)),kX.g?kX.g(a,lX):kX.call(null,a,lX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.yG,kX.g?kX.g(b,lX):kX.call(null,b,lX),$CLJS.Fd(c)?c:$W(c),$W(d)],null)});
LX.m(null,$CLJS.fJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fJ,b,$W(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fJ,$CLJS.Yv],null)});LX.m(null,$CLJS.dJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dJ,b,$W(a)],null)});
LX.m(null,$CLJS.nJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nJ,kX.g?kX.g(b,lX):kX.call(null,b,lX),c,$W(a)],null)});LX.m(null,$CLJS.tJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tJ,kX.g?kX.g(b,lX):kX.call(null,b,lX),c,$W(a)],null)});
LX.m(null,$CLJS.lJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lJ,kX.g?kX.g(b,lX):kX.call(null,b,lX),$W(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lJ,kX.g?kX.g(b,lX):kX.call(null,b,lX)],null)});
LX.m(null,$CLJS.rJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rJ,kX.g?kX.g(b,lX):kX.call(null,b,lX),$W(c),$W(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rJ,kX.g?kX.g(b,lX):kX.call(null,b,lX),$W(c)],null)});
LX.m(null,$CLJS.cJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cJ,kX.g?kX.g(b,lX):kX.call(null,b,lX),kX.g?kX.g(c,lX):kX.call(null,c,lX),$W(a)],null)});LX.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,b,a],null)});
LX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$W(a)],null),$CLJS.hf.h(function(c){return kX.g?kX.g(c,lX):kX.call(null,c,lX)}),b)});
var MX=function MX(a){if($CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(a))){var c=$W(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.Zn,null,$CLJS.cs,null,$CLJS.BG,null,$CLJS.vG,null,$CLJS.ot,null,$CLJS.nG,null,$CLJS.GG,null,$CLJS.HG,null,$CLJS.SF,null,$CLJS.YF,null,$CLJS.bs,null,$CLJS.TF,null,$CLJS.AG,null,$CLJS.tG,null,$CLJS.jk,null,$CLJS.Wx,null,$CLJS.gG,null,$CLJS.zG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(iX(a))?(a=$CLJS.A(a),MX.h?MX.h(a):MX.call(null,
a)):null},Ila=new $CLJS.h(null,8,[$CLJS.mj,$W,$CLJS.oQ,function(a){a=aX(a);return $CLJS.y($CLJS.LQ.h(a))?$CLJS.Sk.j(a,$CLJS.LQ,kla):a},$CLJS.JF,new $CLJS.h(null,6,[$CLJS.bG,function NX(a){if($CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(a)))return $W(a);if($CLJS.n(jX(FX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[FX,NX.h?NX.h(a):NX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(iX(a))?MX($CLJS.hd(a)):null)?$CLJS.Rk.g(NX,a):kX.g?kX.g(a,
lX):kX.call(null,a,lX)},$CLJS.dQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[eX(u),kX.g?kX.g(t,lX):kX.call(null,t,lX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[eX(l),kX.g?kX.g(f,lX):kX.call(null,f,lX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.TR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(iX(t))?LX.h(t):$CLJS.ce(LX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(iX(l))?LX.h(l):$CLJS.ce(LX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.NQ,function(a){a=aX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.oQ);if($CLJS.n(b))return a=$CLJS.EV(a,new $CLJS.h(null,1,[$CLJS.oQ,$CLJS.JF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.oQ],null),a=kX.g?kX.g(a,b):kX.call(null,a,b),$CLJS.EV(a,new $CLJS.h(null,1,[$CLJS.JF,$CLJS.oQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JF],null);return kX.g?kX.g(a,b):kX.call(null,a,b)},$CLJS.WO,new $CLJS.h(null,1,[DX,mX],null),$CLJS.IN,new $CLJS.h(null,1,[DX,function(a){a=kX.g?kX.g(a,$CLJS.JF):kX.call(null,a,$CLJS.JF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.HE),d=$CLJS.J.g(b,$CLJS.WE);a=$CLJS.J.g(b,$CLJS.$E);b=$CLJS.n(c)?$CLJS.Sk.j(b,$CLJS.HE,$W):b;d=$CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.Sk.j(b,$CLJS.WE,$W):b;return $CLJS.n(a)?$CLJS.Sk.j(d,$CLJS.$E,eX):d}],null)],null),$CLJS.Ly,
new $CLJS.h(null,1,[$CLJS.tO,$CLJS.Td],null),$CLJS.zN,new $CLJS.h(null,1,[DX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.mj),d=$CLJS.J.g(b,$CLJS.yJ),e=$CLJS.J.g(b,$CLJS.Zi);a=$CLJS.J.g(b,GX);b=$CLJS.n(e)?$CLJS.Sk.j(b,$CLJS.Zi,eX):b;c=$CLJS.n(c)?$CLJS.Sk.j(b,$CLJS.mj,$W):b;d=$CLJS.n(d)?$CLJS.Sk.j(c,$CLJS.yJ,function(f){return kX.g?kX.g(f,lX):kX.call(null,f,lX)}):c;d=$CLJS.n(a)?$CLJS.BX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[GX,Ala],null),function(f){return kX.g?kX.g(f,lX):kX.call(null,f,lX)}):d;return $CLJS.n(a)?
$CLJS.BX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[GX,wla],null),function(f){return kX.g?kX.g(f,lX):kX.call(null,f,lX)}):d}],null),$CLJS.uN,function(a){return null==a?null:$W(a)},$CLJS.WO,new $CLJS.h(null,1,[DX,mX],null),KX,$W],null),kX=function kX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return kX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
kX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.FA(Ila,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=$W(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,kX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=$W(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,kX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(iX(a))?LX.h(a):$CLJS.wd(a)?$CLJS.Rk.g(function(e){return kX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),DX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.mE("Error normalizing form: {0}",$CLJS.H([$CLJS.xV(d)])),new $CLJS.h(null,3,[$CLJS.cA,a,$CLJS.pl,c,zla,b],null),d);}throw e;}};kX.A=1;kX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var oX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(iX(f))?$CLJS.A(f):null},e,a,b,c,d)}();oX.m(null,$CLJS.ci,function(a){return a});
oX.m(null,$CLJS.mG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(jX($CLJS.mG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return oX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,c,$CLJS.Fe($CLJS.gl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,b,$CLJS.Fe(a)],null)});
oX.m(null,$CLJS.bG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,b,a],null)});oX.m(null,$CLJS.aL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(iX(a))?oX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,a,null],null)});
oX.m(null,JX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,b,new $CLJS.h(null,1,[$CLJS.wB,a],null)],null)});oX.m(null,Bla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=pX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=pX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,a,$CLJS.R.j(c,$CLJS.JN,b)],null)});
oX.m(null,Cla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=pX(a);return $CLJS.Se.v($CLJS.MV,a,$CLJS.R,$CLJS.H([$CLJS.yP,b]))});
oX.m(null,IX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=pX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.wB);$CLJS.Va(d)||$CLJS.QS.g(d,b)?a=$CLJS.Se.v($CLJS.MV,a,$CLJS.R,$CLJS.H([$CLJS.sG,b])):(c=$CLJS.OA($CLJS.Jy),$CLJS.n($CLJS.NA("metabase.mbql.util",c))&&(b=$CLJS.mE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.MA("metabase.mbql.util",c,$CLJS.Fw(),b):$CLJS.MA("metabase.mbql.util",c,$CLJS.Fw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),oX.h(new $CLJS.P(null,3,5,$CLJS.Q,[IX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
oX.m(null,$CLJS.HV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=pX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,b,$CLJS.R.j(e,$CLJS.zR,$CLJS.gl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.HE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var OX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.vs,$CLJS.xs],null)),PX=null,QX=0,RX=0;;)if(RX<QX){var SX=PX.X(null,RX);oX.m(null,SX,function(){return function(a){return qX(a)}}(OX,PX,QX,RX,SX));RX+=1}else{var TX=$CLJS.y(OX);if(TX){var UX=TX;if($CLJS.Ad(UX)){var VX=$CLJS.lc(UX),Jla=$CLJS.mc(UX),Kla=VX,Lla=$CLJS.D(VX);OX=Jla;PX=Kla;QX=Lla}else{var WX=$CLJS.A(UX);oX.m(null,WX,function(){return function(a){return qX(a)}}(OX,PX,QX,RX,WX,UX,TX));OX=$CLJS.B(UX);PX=null;QX=0}RX=0}else break}
oX.m(null,$CLJS.VF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,pX(a),pX(b)],null),c)});oX.m(null,$CLJS.yG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=pX(a);a=$CLJS.n(fX($CLJS.mG,a))?$CLJS.MV.l(c,$CLJS.Hk,$CLJS.H([$CLJS.sG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yG,a],null),b)});
for(var XX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.dG,$CLJS.xG,$CLJS.lG,$CLJS.eG,$CLJS.$r,$CLJS.DG,$CLJS.Xr,$CLJS.Zr,$CLJS.Tr,$CLJS.Vr,$CLJS.QF,$CLJS.XF,$CLJS.OF,$CLJS.ZF,$CLJS.PF],null)),YX=null,ZX=0,$X=0;;)if($X<ZX){var aY=YX.X(null,$X);oX.m(null,aY,function(){return function(a){return rX(a)}}(XX,YX,ZX,$X,aY));$X+=1}else{var bY=$CLJS.y(XX);if(bY){var cY=bY;if($CLJS.Ad(cY)){var dY=$CLJS.lc(cY),Mla=$CLJS.mc(cY),Nla=dY,Ola=$CLJS.D(dY);XX=Mla;YX=Nla;ZX=Ola}else{var eY=$CLJS.A(cY);oX.m(null,eY,
function(){return function(a){return rX(a)}}(XX,YX,ZX,$X,eY,cY,bY));XX=$CLJS.B(cY);YX=null;ZX=0}$X=0}else break}oX.m(null,yla,function(){return null});oX.m(null,$CLJS.zM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,oX.h(b),a],null)});
oX.m(null,FX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=oX.h;var e=$CLJS.Q;b=oX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,xla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.rE,a],null);return c.call(oX,new $CLJS.P(null,3,5,e,[$CLJS.zM,b,a],null))});
for(var fY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wx,$CLJS.nG],null)),gY=null,hY=0,iY=0;;)if(iY<hY){var jY=gY.X(null,iY);oX.m(null,jY,function(){return function(a){return sX(a)}}(fY,gY,hY,iY,jY));iY+=1}else{var kY=$CLJS.y(fY);if(kY){var lY=kY;if($CLJS.Ad(lY)){var mY=$CLJS.lc(lY),Pla=$CLJS.mc(lY),Qla=mY,Rla=$CLJS.D(mY);fY=Pla;gY=Qla;hY=Rla}else{var nY=$CLJS.A(lY);oX.m(null,nY,function(){return function(a){return sX(a)}}(fY,gY,hY,iY,nY,lY,kY));fY=$CLJS.B(lY);gY=null;hY=0}iY=0}else break}
for(var oY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.gG,$CLJS.IG,$CLJS.SF,$CLJS.BG,$CLJS.zG,$CLJS.Zn,$CLJS.jk,$CLJS.AG,$CLJS.TF],null)),pY=null,qY=0,rY=0;;)if(rY<qY){var sY=pY.X(null,rY);oX.m(null,sY,function(){return function(a){return tX(a)}}(oY,pY,qY,rY,sY));rY+=1}else{var tY=$CLJS.y(oY);if(tY){var uY=tY;if($CLJS.Ad(uY)){var vY=$CLJS.lc(uY),Sla=$CLJS.mc(uY),Tla=vY,Ula=$CLJS.D(vY);oY=Sla;pY=Tla;qY=Ula}else{var wY=$CLJS.A(uY);oX.m(null,wY,function(){return function(a){return tX(a)}}(oY,pY,qY,rY,
wY,uY,tY));oY=$CLJS.B(uY);pY=null;qY=0}rY=0}else break}oX.m(null,$CLJS.YF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,pX(b),a],null)});
for(var xY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,$CLJS.vG],null)),yY=null,zY=0,AY=0;;)if(AY<zY){var BY=yY.X(null,AY);oX.m(null,BY,function(){return function(a){return uX(a)}}(xY,yY,zY,AY,BY));AY+=1}else{var CY=$CLJS.y(xY);if(CY){var DY=CY;if($CLJS.Ad(DY)){var EY=$CLJS.lc(DY),Vla=$CLJS.mc(DY),Wla=EY,Xla=$CLJS.D(EY);xY=Vla;yY=Wla;zY=Xla}else{var FY=$CLJS.A(DY);oX.m(null,FY,function(){return function(a){return uX(a)}}(xY,yY,zY,AY,FY,DY,CY));xY=$CLJS.B(DY);yY=null;zY=0}AY=0}else break}
oX.m(null,$CLJS.GG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,oX.h(b),oX.h(a)],null)});
oX.m(null,$CLJS.WF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(oX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,b],null)),kX.l(a,$CLJS.H([lX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[oX.h(v),oX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[oX.h(m),oX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
oX.m(null,$CLJS.FF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,oX.h(a),$CLJS.E.g(0,b)?1:oX.h(b)],null),$CLJS.hf.g(oX,c))});
var wX=function wX(a){return function f(d,e){try{if($CLJS.n(function(){var x=cX($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=cX($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Wk.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.kE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.cs,null,$CLJS.ot,null,$CLJS.HG,null,$CLJS.bs,null,FX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(MX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.lE(wX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;
}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.Wk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;}else throw d;else throw x;}}($CLJS.Cf,a)},GY=function GY(a){return function f(d,e){try{var k=cX($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.jG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.cG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,EX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,HX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,EX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,HX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof
Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.jG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,pX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.cG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG,pX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;
}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.Wk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Wk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.il.h($CLJS.hf.g(GY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof
Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.dX(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Cf,a)},zX=$CLJS.Pk.g(bX,function(a){var b=AX($CLJS.bG.h(a))?$CLJS.Sk.j(a,$CLJS.bG,nla):a;b=AX($CLJS.aG.h(a))?$CLJS.Sk.j(b,$CLJS.aG,ola):b;b=AX($CLJS.WE.h(a))?$CLJS.Sk.j(b,$CLJS.WE,$CLJS.Ve($CLJS.Rk,nX)):b;b=AX($CLJS.TR.h(a))?$CLJS.Sk.j(b,$CLJS.TR,GY):b;return AX($CLJS.NQ.h(a))?
$CLJS.Sk.j(b,$CLJS.NQ,qla):b}),Yla=new $CLJS.h(null,3,[$CLJS.oQ,$CLJS.Td,$CLJS.JF,new $CLJS.h(null,2,[$CLJS.NQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.oQ);if($CLJS.n(b))return a=$CLJS.EV(a,new $CLJS.h(null,1,[$CLJS.oQ,$CLJS.JF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.oQ],null),a=CX.g?CX.g(a,b):CX.call(null,a,b),$CLJS.EV(a,new $CLJS.h(null,1,[$CLJS.JF,$CLJS.oQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JF],null);return CX.g?CX.g(a,b):CX.call(null,a,b)},$CLJS.IN,new $CLJS.h(null,1,
[DX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JF],null);return CX.g?CX.g(a,b):CX.call(null,a,b)}],null)],null),KX,$CLJS.Td],null),CX=function CX(a){switch(arguments.length){case 1:return CX.h(arguments[0]);case 2:return CX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};CX.h=function(a){return CX.g(a,$CLJS.Cf)};
CX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.FA(Yla,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?ula(a,b):$CLJS.wd(a)?vla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.cA,a,$CLJS.pl,b],null),d);throw d;}};CX.A=2;
$CLJS.HY=function(){var a=$CLJS.Pk.l(CX,tla,rla,$CLJS.H([kX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.mE("Error normalizing query: {0}",$CLJS.H([$CLJS.xV(c)])),new $CLJS.h(null,1,[$CLJS.JF,b],null),c);}throw d;}}}();$CLJS.IY=function IY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.HY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Dt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=IY.g?IY.g(f,b):IY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};