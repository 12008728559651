var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var JY,Zla,LY,$la,ama,bma,NY,dma,ema,fma,gma,QY,RY,SY,TY,VY,ima,WY,XY,jma,kma,lma,PY,cma,YY,ZY,hma,aZ;JY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Zla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.j?$CLJS.mj.j(k,l,m):$CLJS.mj.call(null,k,l,m),$CLJS.nl.j?$CLJS.nl.j(k,l,m):$CLJS.nl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.g?$CLJS.mj.g(k,l):$CLJS.mj.call(null,k,l),$CLJS.nl.g?$CLJS.nl.g(k,l):$CLJS.nl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.h?$CLJS.mj.h(k):$CLJS.mj.call(null,k),$CLJS.nl.h?$CLJS.nl.h(k):$CLJS.nl.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.o?$CLJS.mj.o():$CLJS.mj.call(null),$CLJS.nl.o?$CLJS.nl.o():$CLJS.nl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.mj,m,t,u,v),$CLJS.Se.N($CLJS.nl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.KY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
LY=function(a){return $CLJS.VL($CLJS.qW)(a,$CLJS.Cf,$CLJS.Cf)};$la=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.bG,b),$CLJS.lt(2,2,c))};ama=function(a,b){var c=$CLJS.fm(a,$CLJS.LQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.Ft.g?$CLJS.Ft.g(c,b):$CLJS.Ft.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.LQ,b)}return a};bma=function(a){return $CLJS.gf.g($CLJS.RV(a),$CLJS.SV(a))};
$CLJS.MY=function(a,b){return $CLJS.CW(a)&&$CLJS.E.g($CLJS.A(a),b)};NY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ts,null,$CLJS.tl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Au(2,c):$CLJS.Au(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?JY(2,c):JY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.BX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Qk.h($CLJS.Al),l):$CLJS.xd(k)?$CLJS.LA($CLJS.Wa,l):l}):$CLJS.Hk.g(a,f)};
dma=function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return NY(b,cma,d)},a,bma(a))};
ema=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.tL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(Zla(),$CLJS.lf($CLJS.Pk.j(OY,$CLJS.A,$CLJS.nl),$CLJS.Xs.h(LY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=NY(d,f,k),m=$CLJS.OA($CLJS.Jy);if($CLJS.n($CLJS.NA("metabase.lib.convert",m))){var t=$CLJS.EB.l($CLJS.H([$CLJS.Ez,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.EB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.$L(LY(d));$CLJS.MA("metabase.lib.convert",
m,$CLJS.zW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Ez,z.call(x,$CLJS.H([C]))])),$CLJS.EB.l($CLJS.H([$CLJS.tz,$CLJS.AW($CLJS.A($CLJS.hu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=dma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.tL.h(a))-1))return a;b+=1}else a=$CLJS.Sk.N(a,$CLJS.tL,$CLJS.R,b,d)}};
fma=function(a){var b=$CLJS.NW();return $CLJS.Rk.g(function(c){return $CLJS.E.g($CLJS.$E.h(c),"__join")?$CLJS.Sk.j(c,$CLJS.$E,b):c},a)};gma=function(a){return"string"===typeof $CLJS.jP.h(a)?$CLJS.Hk.g($CLJS.R.j(a,$CLJS.TV,$CLJS.LW($CLJS.jP.h(a))),$CLJS.jP):a};QY=function(a,b){var c=$CLJS.J.j(a,b,PY);if($CLJS.E.g(c,PY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[hma,a,$CLJS.$w,b],null));return c};
RY=function(){return $CLJS.Qk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};SY=function(a){return $CLJS.eg.j($CLJS.N,RY(),a)};TY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Pk.g(RY(),$CLJS.Qk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Ei)})),a))};
VY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.UY),c);a=$CLJS.E.g(a,$CLJS.WF)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(TY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zM,a,b],null):a};ima=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.hf.h(function(b){return $CLJS.GA(b,$CLJS.JB)}),$CLJS.hf.h($CLJS.UY)),$CLJS.HW.h(a))};
WY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.tL);a=$CLJS.A($CLJS.fb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.UY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.NQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.WO,ima(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.sV.h(c)],null)},null,a));return $CLJS.n($CLJS.oQ.h(a))?$CLJS.EV(a,new $CLJS.h(null,1,[$CLJS.oQ,$CLJS.JF],null)):a};
XY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.ZW(a,b,$CLJS.Pk.g($CLJS.UY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.ZW(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Cs],null),$CLJS.hf.h($CLJS.UY),e)}):d;return $CLJS.EV(a,$CLJS.Ee([b,c]))};jma=function(a){var b=$CLJS.TV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Hk.g(a,$CLJS.TV),$CLJS.jP,["card__",$CLJS.p.h(b)].join("")):a};
kma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);lma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);PY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);cma=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);YY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
ZY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.$Y=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);hma=new $CLJS.M(null,"m","m",1632677161);aZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var OY,mma,nma,oma,pma,qma,rma,sma,tma,uma,vma;$CLJS.bZ=$CLJS.N;$CLJS.cZ=$CLJS.N;OY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.nW,null,$CLJS.WE,null,$CLJS.IN,null,$CLJS.dQ,null,$CLJS.aG,null,$CLJS.TR,null,$CLJS.bG,null],null),null);mma=$CLJS.Xe($CLJS.N);nma=$CLJS.Xe($CLJS.N);oma=$CLJS.Xe($CLJS.N);pma=$CLJS.Xe($CLJS.N);qma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.kC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.dZ=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.iC,qma,mma,nma,oma,pma);$CLJS.dZ.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.uW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.dZ),d))}else b=a;return b});$CLJS.dZ.m(null,$CLJS.mW,function(a){return a});
$CLJS.dZ.m(null,$CLJS.$V,function(a){var b=$CLJS.dZ.h($CLJS.bG.h(a)),c=$CLJS.Fe($CLJS.Rk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.EW($CLJS.dZ.h(t),u)},$CLJS.dQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.DE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.DE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.cZ,k=$CLJS.bZ;$CLJS.cZ=d;$CLJS.bZ=e;try{var l=$la(gma(a),b,$CLJS.H([$CLJS.dQ,c])),m=$CLJS.fb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Sk.j(t,u,$CLJS.dZ)},l,$CLJS.Ik.l(OY,$CLJS.bG,$CLJS.H([$CLJS.dQ])));return $CLJS.n($CLJS.IN.h(m))?$CLJS.Sk.j(m,$CLJS.IN,fma):m}finally{$CLJS.bZ=k,$CLJS.cZ=f}});$CLJS.dZ.m(null,$CLJS.gW,function(a){return ama(a,function(b){return $CLJS.ZW(b,$CLJS.GF,$CLJS.dZ)})});
$CLJS.dZ.m(null,$CLJS.oL,function(a){a=$CLJS.Sk.j($CLJS.Sk.j(a,$CLJS.wL,$CLJS.dZ),$CLJS.tL,$CLJS.dZ);var b=$CLJS.n($CLJS.WE.h(a))?$CLJS.Sk.j(a,$CLJS.WE,function(c){return $CLJS.Ml(c)?$CLJS.Rk.g($CLJS.dZ,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.$E.h(a))?$CLJS.R.j(b,$CLJS.$E,"__join"):b});$CLJS.dZ.m(null,$CLJS.pB,function(a){return $CLJS.Rk.g($CLJS.dZ,a)});
$CLJS.dZ.m(null,$CLJS.rB,function(a){return $CLJS.n($CLJS.mj.h(a))?ema($CLJS.R.j($CLJS.Sk.j($CLJS.IW(a),$CLJS.tL,function(b){return $CLJS.Rk.g($CLJS.dZ,b)}),$CLJS.$Y,!0)):$CLJS.Ft(a,$CLJS.dZ)});$CLJS.dZ.m(null,$CLJS.mG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.uW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,a,b],null))});
$CLJS.dZ.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.EV(a,new $CLJS.h(null,3,[$CLJS.jD,$CLJS.wB,$CLJS.IO,$CLJS.Vj,$CLJS.bS,$CLJS.yE],null));var c=$CLJS.R.j;var d=$CLJS.Ei.h(a);$CLJS.n(d)||(d=$CLJS.wB.h(a),d=$CLJS.n(d)?d:$CLJS.RE(b));a=c.call($CLJS.R,a,$CLJS.Ei,d);return $CLJS.uW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null))});
$CLJS.dZ.m(null,$CLJS.WF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,$CLJS.Hk.g(c,$CLJS.ci),$CLJS.Rk.g($CLJS.dZ,b)],null);b=$CLJS.uW(b);return null!=a?$CLJS.be.g(b,$CLJS.dZ.h(a)):b});$CLJS.dZ.m(null,$CLJS.tB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.uW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.dZ.m(null,$CLJS.bG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.uW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,QY($CLJS.cZ,c)],null))});$CLJS.dZ.m(null,$CLJS.zM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.dZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.gl.l($CLJS.H([c,a]))],null),d)});rma=$CLJS.Xe($CLJS.N);sma=$CLJS.Xe($CLJS.N);
tma=$CLJS.Xe($CLJS.N);uma=$CLJS.Xe($CLJS.N);vma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.kC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.UY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.iC,vma,rma,sma,tma,uma);
$CLJS.UY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.UY,d);a=TY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.UY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.Ft(SY(a),$CLJS.UY):
a;return a});for(var eZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[aZ,ZY],null)),fZ=null,gZ=0,hZ=0;;)if(hZ<gZ){var wma=fZ.X(null,hZ);$CLJS.uF(wma,YY);hZ+=1}else{var iZ=$CLJS.y(eZ);if(iZ){var jZ=iZ;if($CLJS.Ad(jZ)){var kZ=$CLJS.lc(jZ),xma=$CLJS.mc(jZ),yma=kZ,zma=$CLJS.D(kZ);eZ=xma;fZ=yma;gZ=zma}else{var Ama=$CLJS.A(jZ);$CLJS.uF(Ama,YY);eZ=$CLJS.B(jZ);fZ=null;gZ=0}hZ=0}else break}
for(var lZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Wx,$CLJS.gG,$CLJS.vG,$CLJS.SF,$CLJS.jk,$CLJS.AG,$CLJS.Zn,$CLJS.YF,$CLJS.tG,$CLJS.BG,$CLJS.zG,$CLJS.GG],null)),mZ=null,nZ=0,oZ=0;;)if(oZ<nZ){var Bma=mZ.X(null,oZ);$CLJS.uF(Bma,aZ);oZ+=1}else{var pZ=$CLJS.y(lZ);if(pZ){var qZ=pZ;if($CLJS.Ad(qZ)){var rZ=$CLJS.lc(qZ),Cma=$CLJS.mc(qZ),Dma=rZ,Ema=$CLJS.D(rZ);lZ=Cma;mZ=Dma;nZ=Ema}else{var Fma=$CLJS.A(qZ);$CLJS.uF(Fma,aZ);lZ=$CLJS.B(qZ);mZ=null;nZ=0}oZ=0}else break}
for(var sZ=$CLJS.y($CLJS.yf([$CLJS.bs,$CLJS.ot,$CLJS.cs,$CLJS.HG,$CLJS.WF,$CLJS.pG,$CLJS.NF,$CLJS.JG,$CLJS.EF,$CLJS.$F,$CLJS.IF,$CLJS.kG,$CLJS.rG,$CLJS.KF,$CLJS.dJ,$CLJS.fJ,$CLJS.Ky,$CLJS.$I,$CLJS.hJ,$CLJS.UI,$CLJS.lJ,$CLJS.ZI,$CLJS.SI,$CLJS.kJ,$CLJS.sJ,$CLJS.oJ,$CLJS.YI,$CLJS.vJ,$CLJS.nJ,$CLJS.tJ,$CLJS.MF,$CLJS.FF,$CLJS.KG,$CLJS.dI,$CLJS.oG,$CLJS.Ux,$CLJS.CG,$CLJS.EG,$CLJS.LG,$CLJS.It,$CLJS.Ot],!0)),tZ=null,uZ=0,vZ=0;;)if(vZ<uZ){var Gma=tZ.X(null,vZ);$CLJS.uF(Gma,ZY);vZ+=1}else{var wZ=$CLJS.y(sZ);
if(wZ){var xZ=wZ;if($CLJS.Ad(xZ)){var yZ=$CLJS.lc(xZ),Hma=$CLJS.mc(xZ),Ima=yZ,Jma=$CLJS.D(yZ);sZ=Hma;tZ=Ima;uZ=Jma}else{var Kma=$CLJS.A(xZ);$CLJS.uF(Kma,ZY);sZ=$CLJS.B(xZ);tZ=null;uZ=0}vZ=0}else break}$CLJS.UY.m(null,YY,function(a){return VY(a)});$CLJS.UY.m(null,$CLJS.rB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Pk.g(RY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.UY.h(b)],null)})),a)});
$CLJS.UY.m(null,$CLJS.bG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=TY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bG,QY($CLJS.bZ,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.xV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.UY.m(null,$CLJS.pB,function(a){return $CLJS.Rk.g($CLJS.UY,a)});$CLJS.UY.m(null,$CLJS.mG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,$CLJS.UY.h(a),TY(b)],null)});
$CLJS.UY.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=TY($CLJS.EV(b,new $CLJS.h(null,3,[$CLJS.wB,$CLJS.jD,$CLJS.Vj,$CLJS.IO,$CLJS.yE,$CLJS.bS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null)});
$CLJS.UY.m(null,$CLJS.oL,function(a){var b=SY(a);a=0==$CLJS.$E.h(a).lastIndexOf("__join",0)?$CLJS.Hk.g(b,$CLJS.$E):b;return $CLJS.gl.l($CLJS.H([$CLJS.Ft($CLJS.Hk.l(a,$CLJS.tL,$CLJS.H([$CLJS.wL])),$CLJS.UY),XY($CLJS.em(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wL],null)),$CLJS.wL,$CLJS.VR),WY(a)]))});
$CLJS.UY.m(null,$CLJS.$V,function(a){var b=$CLJS.bG.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.DE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.DE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.cZ,e=$CLJS.bZ;$CLJS.cZ=c;$CLJS.bZ=b;try{return $CLJS.fb(function(f,k){return $CLJS.ZW(f,
k,$CLJS.UY)},XY($CLJS.ZW($CLJS.ZW(jma(SY(a)),$CLJS.bG,function(f){return $CLJS.Rk.g(VY,f)}),$CLJS.dQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.UY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DW(C),$CLJS.E.g($CLJS.Dj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.UY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DW(x),$CLJS.E.g($CLJS.Dj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.nW,$CLJS.RF),$CLJS.Ik.l(OY,$CLJS.bG,$CLJS.H([$CLJS.nW,$CLJS.dQ])))}finally{$CLJS.bZ=e,$CLJS.cZ=d}});$CLJS.UY.m(null,$CLJS.gW,function(a){return $CLJS.Ft(SY(a),$CLJS.UY)});
$CLJS.UY.m(null,$CLJS.mW,function(a){try{var b=SY(a),c=$CLJS.zN.h(b),d=WY(b),e=$CLJS.E.g($CLJS.cu.h($CLJS.id($CLJS.tL.h(a))),$CLJS.gW)?$CLJS.oQ:$CLJS.JF;return $CLJS.gl.l($CLJS.H([$CLJS.Ft($CLJS.Hk.l(b,$CLJS.tL,$CLJS.H([$CLJS.zN,$CLJS.$Y])),$CLJS.UY),function(){var k=$CLJS.Ee([$CLJS.mj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.zN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.xV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.JF,a],null),f);}});
$CLJS.zZ=function(){function a(d,e,f){f=$CLJS.IY(null,$CLJS.Rz(f,$CLJS.H([$CLJS.yi,!0])));var k=$CLJS.KW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.bG);k=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.DE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.DE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.cZ,t=$CLJS.bZ;$CLJS.cZ=k;$CLJS.bZ=l;try{try{return $CLJS.dZ.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.xV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.JF,d,$CLJS.HL,e,lma,f,kma,$CLJS.cZ],null),u);}}finally{$CLJS.bZ=t,$CLJS.cZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();