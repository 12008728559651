var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.WJ=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.XJ=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.YJ=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var ZJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,$CLJS.vs],null)),$J=null,aK=0,bK=0;;)if(bK<aK){var $ga=$J.X(null,bK);$CLJS.DF($ga,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null)],null)]));bK+=1}else{var cK=$CLJS.y(ZJ);if(cK){var dK=cK;if($CLJS.Ad(dK)){var eK=$CLJS.lc(dK),aha=$CLJS.mc(dK),
bha=eK,cha=$CLJS.D(eK);ZJ=aha;$J=bha;aK=cha}else{var dha=$CLJS.A(dK);$CLJS.DF(dha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null)],null)]));ZJ=$CLJS.B(dK);$J=null;aK=0}bK=0}else break}$CLJS.BF($CLJS.xs,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)]));
for(var fK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.DG],null)),gK=null,hK=0,iK=0;;)if(iK<hK){var eha=gK.X(null,iK);$CLJS.DF(eha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null)],null)]));iK+=1}else{var jK=$CLJS.y(fK);if(jK){var kK=jK;if($CLJS.Ad(kK)){var lK=$CLJS.lc(kK),fha=$CLJS.mc(kK),
gha=lK,hha=$CLJS.D(lK);fK=fha;gK=gha;hK=hha}else{var iha=$CLJS.A(kK);$CLJS.DF(iha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null)],null)]));fK=$CLJS.B(kK);gK=null;hK=0}iK=0}else break}
for(var mK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xr,$CLJS.Zr,$CLJS.Tr,$CLJS.Vr],null)),nK=null,oK=0,pK=0;;)if(pK<oK){var jha=nK.X(null,pK);$CLJS.BF(jha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)]));pK+=1}else{var qK=$CLJS.y(mK);if(qK){var rK=qK;if($CLJS.Ad(rK)){var sK=$CLJS.lc(rK),kha=$CLJS.mc(rK),lha=sK,mha=$CLJS.D(sK);mK=kha;nK=lha;oK=mha}else{var nha=$CLJS.A(rK);$CLJS.BF(nha,$CLJS.H([$CLJS.ot,$CLJS.Lj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)]));mK=$CLJS.B(rK);nK=null;oK=0}pK=0}else break}$CLJS.BF($CLJS.PF,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)]));
$CLJS.BF($CLJS.VF,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)]));
for(var tK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,$CLJS.ZF],null)),uK=null,vK=0,wK=0;;)if(wK<vK){var oha=uK.X(null,wK);$CLJS.BF(oha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)]));wK+=1}else{var xK=$CLJS.y(tK);if(xK){var yK=xK;if($CLJS.Ad(yK)){var zK=$CLJS.lc(yK),pha=$CLJS.mc(yK),qha=zK,rha=$CLJS.D(zK);tK=pha;uK=qha;vK=rha}else{var sha=$CLJS.A(yK);$CLJS.BF(sha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)]));tK=$CLJS.B(yK);
uK=null;vK=0}wK=0}else break}
for(var AK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,$CLJS.XF],null)),BK=null,CK=0,DK=0;;)if(DK<CK){var tha=BK.X(null,DK);$CLJS.BF(tha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)]));DK+=1}else{var EK=$CLJS.y(AK);if(EK){var FK=EK;if($CLJS.Ad(FK)){var GK=$CLJS.lc(FK),uha=$CLJS.mc(FK),vha=GK,wha=$CLJS.D(GK);AK=uha;BK=vha;CK=wha}else{var xha=$CLJS.A(FK);$CLJS.BF(xha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)]));AK=$CLJS.B(FK);
BK=null;CK=0}DK=0}else break}
for(var HK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null)],null),IK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dG,$CLJS.xG,$CLJS.lG,$CLJS.eG],null)),JK=null,KK=0,LK=0;;)if(LK<KK){var MK=JK.X(null,LK);$CLJS.MG.v(MK,$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,MK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.BE,HK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.kF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null));LK+=1}else{var NK=$CLJS.y(IK);if(NK){var OK=NK;if($CLJS.Ad(OK)){var PK=$CLJS.lc(OK),yha=$CLJS.mc(OK),zha=PK,Aha=$CLJS.D(PK);IK=yha;JK=zha;KK=Aha}else{var QK=$CLJS.A(OK);$CLJS.MG.v(QK,$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,QK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.BE,HK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.kF],null)],null));IK=$CLJS.B(OK);JK=null;KK=0}LK=0}else break}
$CLJS.MG.v($CLJS.yG,$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.yG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.BE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.Yv,$CLJS.$x,$CLJS.UF],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gH],null)],null));$CLJS.MG.v($CLJS.LF,$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.LF],null),$CLJS.BE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.oE,$CLJS.wE],null)],null));
$CLJS.X($CLJS.WJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.XJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ss,$CLJS.$r,$CLJS.DG,$CLJS.VF,$CLJS.PF,$CLJS.Xr,$CLJS.Tr,$CLJS.Zr,$CLJS.Vr,$CLJS.OF,$CLJS.ZF,$CLJS.QF,$CLJS.XF,$CLJS.lG,$CLJS.eG,$CLJS.dG,$CLJS.xG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,$CLJS.Xi],null)],null));