var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var g9,i9,h9,Mva,o9,Nva,p9,Ova,Pva,Qva,Rva,j9,Sva,u9,Tva,Uva,Vva;g9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Ca(a,b):null};i9=function(a,b){return h9(a,b,$CLJS.N)};
h9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,j9,!0);if($CLJS.xd(b))return $CLJS.eg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=g9(b,"_plainObject");d=null==d?null:$CLJS.Sz(d);return null==d?null:$CLJS.Fe(d)}():null;return $CLJS.n(c)?$CLJS.eg.j($CLJS.N,a,c):$CLJS.eg.j($CLJS.N,$CLJS.Pk.j($CLJS.hf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,g9(b,d)],null)}),$CLJS.Qk.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.Ba(b))};
Mva=function(a){var b=k9.h(a),c=l9.h(a),d=m9.h(a);return $CLJS.Pk.j($CLJS.hf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.zh.h($CLJS.gB.h?$CLJS.gB.h(f):$CLJS.gB.call(null,f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.td(b)?$CLJS.Td:$CLJS.Qk.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Hd(b,f)}),$CLJS.Va(c)?$CLJS.Td:$CLJS.hf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,
1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};
o9=function(a,b){var c=Mva(a),d=n9.h(a);return function(e){try{var f=$CLJS.R.j(h9(c,e,b),$CLJS.cu,d),k=$CLJS.OA($CLJS.Gy);$CLJS.n($CLJS.NA("metabase.lib.js.metadata",k))&&$CLJS.MA("metabase.lib.js.metadata",k,$CLJS.zW("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.Zi.h(f),$CLJS.AW(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.OA($CLJS.Hy),$CLJS.n($CLJS.NA("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.MA("metabase.lib.js.metadata",k,$CLJS.zW("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.Mh.l($CLJS.H([e])),$CLJS.xV(f)])),f):$CLJS.MA("metabase.lib.js.metadata",k,$CLJS.zW(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.Mh.l($CLJS.H([e])),$CLJS.xV(f)])),null)),null;throw l;}}};Nva=function(a){return i9($CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.zh.h($CLJS.gB.h?$CLJS.gB.h(c):$CLJS.gB.call(null,c));c=$CLJS.E.g(c,$CLJS.HV)?$CLJS.HE:c;b=$CLJS.E.g(c,$CLJS.HE)?$CLJS.zh.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
p9=function(a){return $CLJS.Rk.g(o9($CLJS.mG,$CLJS.N),a)};Ova=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.q9=new $CLJS.M(null,"cards","cards",169174038);Pva=new $CLJS.M(null,"binning-info","binning-info",-2117036653);Qva=new $CLJS.M(null,"db","db",993250759);$CLJS.r9=new $CLJS.M(null,"metrics","metrics",394093469);Rva=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.s9=new $CLJS.M(null,"segments","segments",1937535949);
j9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);Sva=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.t9=new $CLJS.M(null,"tables","tables",1334623052);u9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);Tva=new $CLJS.M(null,"schema-name","schema-name",1666725119);Uva=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);Vva=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.v9=new $CLJS.M(null,"databases","databases",2040134125);var k9,Wva=$CLJS.Xe($CLJS.N),Xva=$CLJS.Xe($CLJS.N),Yva=$CLJS.Xe($CLJS.N),Zva=$CLJS.Xe($CLJS.N),$va=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null)),x9,pwa,qwa,rwa,swa,twa;k9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.zh,$va,Wva,Xva,Yva,Zva);k9.m(null,$CLJS.ci,function(){return null});var l9,awa=$CLJS.Xe($CLJS.N),bwa=$CLJS.Xe($CLJS.N),cwa=$CLJS.Xe($CLJS.N),dwa=$CLJS.Xe($CLJS.N),ewa=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
l9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.zh,ewa,awa,bwa,cwa,dwa);l9.m(null,$CLJS.ci,function(){return null});var n9,fwa=$CLJS.Xe($CLJS.N),gwa=$CLJS.Xe($CLJS.N),hwa=$CLJS.Xe($CLJS.N),iwa=$CLJS.Xe($CLJS.N),jwa=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));n9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","lib-type"),$CLJS.zh,jwa,fwa,gwa,hwa,iwa);
var m9,kwa=$CLJS.Xe($CLJS.N),lwa=$CLJS.Xe($CLJS.N),mwa=$CLJS.Xe($CLJS.N),nwa=$CLJS.Xe($CLJS.N),owa=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));m9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Td,owa,kwa,lwa,mwa,nwa);m9.m(null,$CLJS.ci,function(){return null});
$CLJS.w9=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.zh.h(f)},e,a,b,c,d)}();pwa=$CLJS.Xe($CLJS.N);qwa=$CLJS.Xe($CLJS.N);rwa=$CLJS.Xe($CLJS.N);swa=$CLJS.Xe($CLJS.N);twa=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
x9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.zh,twa,pwa,qwa,rwa,swa);$CLJS.w9.m(null,$CLJS.ci,function(a,b){var c=o9(a,$CLJS.N);return i9($CLJS.hf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yW(e),new $CLJS.Jh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),g9(b,x9.h(a)))});n9.m(null,$CLJS.ZP,function(){return $CLJS.iL});
k9.m(null,$CLJS.ZP,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.WE,null,$CLJS.t9,null],null),null)});l9.m(null,$CLJS.ZP,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.Rz(b,$CLJS.H([$CLJS.yi,!0]));case "features":return $CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.zh),b);case "native-permissions":return $CLJS.zh.h(b);default:return b}}});x9.m(null,$CLJS.ZP,function(){return"databases"});n9.m(null,$CLJS.TW,function(){return $CLJS.ZK});
k9.m(null,$CLJS.TW,function(){return new $CLJS.ah(null,new $CLJS.h(null,5,[u9,null,$CLJS.WE,null,$CLJS.s9,null,$CLJS.ZP,null,$CLJS.r9,null],null),null)});l9.m(null,$CLJS.TW,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.zh.h(b);case "field-order":return $CLJS.zh.h(b);case "initial-sync-status":return $CLJS.zh.h(b);case "visibility-type":return $CLJS.zh.h(b);default:return b}}});
$CLJS.w9.m(null,$CLJS.TW,function(a,b){var c=o9(a,$CLJS.N);return i9($CLJS.Pk.g($CLJS.Qk.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.hf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yW(e),new $CLJS.Jh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),g9(b,"tables"))});n9.m(null,$CLJS.mG,function(){return $CLJS.SK});
k9.m(null,$CLJS.mG,function(){return new $CLJS.ah(null,new $CLJS.h(null,7,[u9,null,$CLJS.TW,null,$CLJS.KL,null,Vva,null,$CLJS.ZP,null,Uva,null,$CLJS.r9,null],null),null)});m9.m(null,$CLJS.mG,function(){return new $CLJS.h(null,4,[$CLJS.eA,$CLJS.UK,$CLJS.ri,$CLJS.OW,$CLJS.fS,$CLJS.vE,Pva,$CLJS.UW],null)});
l9.m(null,$CLJS.mG,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.zh.h(b);case "coercion-strategy":return $CLJS.zh.h(b);case "effective-type":return $CLJS.zh.h(b);case "fingerprint":return $CLJS.xd(b)?$CLJS.AV(b):$CLJS.Rz(b,$CLJS.H([$CLJS.yi,!0]));case "has-field-values":return $CLJS.zh.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.mL;case "breakout":return $CLJS.gL;default:return $CLJS.zh.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.zh.h(b);
case "semantic-type":return $CLJS.zh.h(b);case "visibility-type":return $CLJS.zh.h(b);case "id":return $CLJS.zd(b)?$CLJS.hd(b):b;case "metabase.lib.field/binning":return Nva(b);default:return b}}});
$CLJS.w9.m(null,$CLJS.mG,function(a,b){var c=o9(a,$CLJS.N),d=g9(b,"fields");return i9($CLJS.Xl(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.yW(f);if($CLJS.n(l))return l;l=$CLJS.nh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==g9(d,l);return m?$CLJS.yW(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Jh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});n9.m(null,$CLJS.AN,function(){return $CLJS.lL});
k9.m(null,$CLJS.AN,function(){return new $CLJS.ah(null,new $CLJS.h(null,11,[u9,null,$CLJS.ql,null,Rva,null,$CLJS.TW,null,Qva,null,Ova,null,$CLJS.s9,null,$CLJS.ZP,null,Sva,null,$CLJS.r9,null,Tva,null],null),null)});
l9.m(null,$CLJS.AN,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.EA($CLJS.wd,$CLJS.Ua)(b))?p9(b):$CLJS.Rz(b,$CLJS.H([$CLJS.yi,!0]));case "fields":return p9(b);case "visibility-type":return $CLJS.zh.h(b);case "dataset-query":return $CLJS.Rz(b,$CLJS.H([$CLJS.yi,!0]));case "dataset":return b;default:return b}}});
$CLJS.w9.m(null,$CLJS.AN,function(a,b){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Jh(function(){var d=o9($CLJS.AN,new $CLJS.h(null,1,[j9,!1],null)),e=o9($CLJS.AN,$CLJS.N),f=$CLJS.gl.l;d=$CLJS.R.j(d(g9(g9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.Zi,c);var k=g9(g9(b,"questions"),$CLJS.p.h(c));var l=g9(k,"_card");k=$CLJS.n(l)?l:k;e=e.h?e.h(k):e.call(null,k);return f.call($CLJS.gl,$CLJS.H([d,e]))})],null)}),$CLJS.eg.j($CLJS.eg.j($CLJS.bh,
$CLJS.Xl($CLJS.LW),$CLJS.Ba(g9(b,"tables"))),$CLJS.hf.h($CLJS.yW),$CLJS.Ba(g9(b,"questions"))))});n9.m(null,$CLJS.hG,function(){return $CLJS.YK});k9.m(null,$CLJS.hG,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.TW,null,$CLJS.ZP,null],null),null)});l9.m(null,$CLJS.hG,function(){return function(a,b){return b}});x9.m(null,$CLJS.hG,function(){return"metrics"});n9.m(null,$CLJS.LF,function(){return $CLJS.WK});
k9.m(null,$CLJS.LF,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.TW,null,$CLJS.ZP,null],null),null)});l9.m(null,$CLJS.LF,function(){return function(a,b){return b}});x9.m(null,$CLJS.LF,function(){return"segments"});$CLJS.y9=o9($CLJS.mG,$CLJS.N);